import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "structure";
import { Container } from "semantic-ui-react";
import { Loader } from "components/ui/Loader";
import { Carousel } from "components/ui/Carousel";
import { SectionWrapper } from "./style";
import Title from "assets/graphics/עלינו/יעדיםmdpi.svg";
import { Fade } from "react-awesome-reveal";

export const Goals = observer(({ isDisabled }) => {
  const { goalsStore } = useStore();
  const { isLoading, goals } = goalsStore;

  useEffect(() => {
    goalsStore.fetchItems(null, ["icon"]);
    // eslint-disable-next-line
  }, []);

  return (
    <SectionWrapper>
      <Container>
        <div className="ls-main ls-goals">
          <Fade
            duration={isDisabled ? 0 : 1000}
            direction="right"
            triggerOnce={true}
          >
            <h2>
              <img src={Title} alt="יעדים" className="section-title" />
            </h2>
          </Fade>
          {isLoading ? (
            <Loader inverted />
          ) : (
            <div>
              <Carousel>
                {goals.map((goal) => {
                  const icon = goal?.acf?.icon || "";
                  const text = goal?.acf?.text || "";

                  return (
                    <div key={goal.id} className="ls-carusel-item ls-goal-item">
                      <div>
                        <img src={icon} alt="יעד" />
                        <Fade
                          duration={isDisabled ? 0 : 1000}
                          direction="up"
                          cascade
                          damping={0.1}
                          triggerOnce={true}
                        >
                          <p>{text}</p>
                        </Fade>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
      </Container>
    </SectionWrapper>
  );
});
