import { events } from "../endpoints";
import { Items } from "./Items";
import { makeObservable, computed } from "mobx";

export class Events extends Items {
  constructor() {
    super();
    this.getItems = events.getEvents;
    makeObservable(this, {
      events: computed,
    });
  }

  get events() {
    return Array.isArray(this.items) ? this.items : [];
  }

  async fetchSpesificEvent(idOrSlug) {
    if (isNaN(idOrSlug)) {
      return this.fetchItems({ slug: idOrSlug });
    }
    return this.fetchItems({ id: idOrSlug }).then(() => {
      if (events.length === 0) this.fetchItems({ slug: idOrSlug });
    });
  }
}
