import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "structure";
import * as S from "./style";
import { Container, Card } from "semantic-ui-react";
import { Loader } from "components/ui/Loader";
import { NavLink } from "react-router-dom";
import { Dropdown } from "components/ui/form";
import {
  closeDays,
  closeYears,
  getWeekDay,
  months,
  toSystemDate,
  weekDays,
} from "./eventHelper";
import { UnstyledButton } from "components/style";
import { Icon } from "components/ui/Icon";
import { Form } from "react-final-form";
import { Media } from "components/ui/Media";
import { ButtonWithIcon } from "../Home/style";
import Title from "assets/graphics/אירועי הבית/אירועי הבית הטרנסיmdpi.svg";
import { Fade } from "react-awesome-reveal";

const TODAY = new Date();
const START_DAY = new Date();
START_DAY.setDate(1);

export const Events = observer(() => {
  const { eventsStore } = useStore();
  const { isLoading } = eventsStore;
  const [startDay, setStartDay] = useState(START_DAY);

  const today = {
    month: startDay.getMonth(),
    yaer: startDay.getFullYear(),
  };

  const monthOptions = months.map((month, idx) => ({
    key: idx,
    value: idx,
    text: month,
  }));

  const yearOptions = closeYears(TODAY).map((yaer) => ({
    key: yaer,
    value: yaer,
    text: yaer,
  }));

  const displayedDays = closeDays(startDay).map((date) => ({
    day: date.getDate(),
    month: months[date.getMonth()],
    year: date.getFullYear(),
    date: date,
    weekDay: date.getDay(),
    weekDayName: getWeekDay(date),
  }));

  const canGoBack = startDay.getTime() > TODAY.getTime();

  const goBack = () => {
    changeMonth(startDay.getMonth() - 1);
    window.scrollTo(0, 0);
  };

  const goNext = () => {
    changeMonth(startDay.getMonth() + 1);
    window.scrollTo(0, 0);
  };

  const changeMonth = (month) => {
    const newDate = new Date(startDay.getTime());
    newDate.setDate(1);
    newDate.setMonth(month);
    setStartDay(newDate);
  };

  const changeYear = (year) => {
    const newDate = new Date(startDay.getTime());
    newDate.setDate(1);
    newDate.setYear(year);
    setStartDay(newDate);
  };

  useEffect(() => {
    eventsStore.fetchItems();
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {};

  return (
    <S.Wrapper>
      <Container>
        <div className="ls-header">
          <Fade direction="down" cascade damping={0.1} triggerOnce={true}>
            <h1>
              <img
                src={Title}
                alt="אירועי הבית הטרנסי"
                className="section-title"
              />
            </h1>
            <p>בואו לגלות מהם האירועים הקרובים</p>{" "}
          </Fade>
        </div>

        <Form
          onSubmit={onSubmit}
          render={() => (
            <S.Caledar>
              <Fade direction="up" cascade damping={0.1} triggerOnce={true}>
                <div className="select-month">
                  <UnstyledButton
                    type="button"
                    onClick={goBack}
                    disabled={!canGoBack}
                  >
                    <Icon name="right" height="24px" width="24px" />
                  </UnstyledButton>
                  <Dropdown
                    keyname={today.month}
                    name="month"
                    options={monthOptions}
                    value={today.month}
                    customOnChange={changeMonth}
                  />
                  <Dropdown
                    keyname={today.yaer}
                    name="year"
                    options={yearOptions}
                    value={today.yaer}
                    customOnChange={changeYear}
                  />
                  <UnstyledButton type="button" onClick={goNext}>
                    <Icon name="left" height="24px" width="24px" />
                  </UnstyledButton>
                </div>
              </Fade>
            </S.Caledar>
          )}
        />
        {isLoading ? (
          <Loader inverted />
        ) : (
          <S.EventsWrapper>
            <Fade direction="up" cascade damping={0.02} triggerOnce={true}>
              {Array(5)
                .fill(0)
                .map((_, idx) => (
                  <div key={idx} className="ls-days">
                    <p>{weekDays[idx]}</p>
                  </div>
                ))}
              {Array(
                displayedDays[0].weekDay < 5 ? displayedDays[0].weekDay : 0
              )
                .fill(0)
                .map((_, idx) => (
                  <div key={idx} className="ls-days-empty"></div>
                ))}
              {displayedDays.map((day) => {
                if (day.weekDay > 4) return null;
                return (
                  <div key={day.date} className="ls-day">
                    <div className="ls-date-title">
                      <p>
                        <span className="ls-days-mobile">
                          יום {day.weekDayName}
                        </span>{" "}
                        {day.day} ב{day.month} {day.year}
                      </p>
                    </div>
                    <div className="ls-date-evenets">
                      <EventsInDay day={day} />
                    </div>
                  </div>
                );
              })}
            </Fade>
          </S.EventsWrapper>
        )}
      </Container>
    </S.Wrapper>
  );
});

const EventsInDay = ({ day }) => {
  const { eventsStore } = useStore();
  const events = eventsStore.events.filter(
    (event) => event?.acf?.date === toSystemDate(day.date)
  );

  if (events.length === 0) {
    return <p>אין אירועים להצגה במועד זה</p>;
  }
  return (
    <>
      {events.map((event, idx) => {
        const id = event?.id || "unknown";
        const title = event?.title?.rendered || "";
        const note = event?.acf?.note;
        const time = event?.acf?.time || "";
        const desc = event?.acf?.desc || "No desc.";
        const img = event?.acf?.mainImg;

        return (
          <NavLink key={idx} to={`/event/${id}`}>
            <S.EventCard>
              <Card.Content>
                <Media id={img} />
                <Card.Header>{title}</Card.Header>

                <Card.Description
                  dangerouslySetInnerHTML={{
                    __html: desc.replaceAll("\r\n", " "),
                  }}
                />
                <Card.Meta>
                  <span className="time">{time}</span>
                </Card.Meta>
                {note && (
                  <Card.Meta>
                    <span className="note">{note}</span>
                  </Card.Meta>
                )}
                <ButtonWithIcon>
                  <span>לפרטים נוספים</span>
                  <Icon name="left" size="1em" />
                </ButtonWithIcon>
              </Card.Content>
            </S.EventCard>
          </NavLink>
        );
      })}
    </>
  );
};
