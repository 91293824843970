import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;  
`;

export const FieldWrapper = styled.div`
    &>div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.3em;

        ${({ type }) => {
            if (type === "email" || type === "phone") {
                return `.ui.input > input {
                    direction: ltr;
            text-align: left;
                }`;
            }
}}
                
        .ui.input > input${({ type }) => {
            if (type === "email" || type === "phone") {
                return `:placeholder-shown`;
            }
                }} {
            direction: rtl;
            text-align: right;
        }
        
       
    }

    .ls-error {
        input {
            border: 1px solid var(--primary-dark) !important;
        }
        span {
            color: var(--primary-dark);
            float: left;
            font-size: 1em;
            margin-right: 1em;
        }
        
    }
`;

export const ErrorMessage = styled(Segment)`
    max-width: 400px;
    background: var(--red-soft);
    border: 1px solid var(--red);
    border-radius: 5px;

`;