import styled from 'styled-components';
import { Link as RouterLink } from "react-router-dom";

const radius = "0.6em";


export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ icon }) => icon ? "0.3em" : "0.5em 1em"}; ;

    border: none;
    border-radius: ${({ round }) => round ? "6em" : radius};
    cursor: pointer;
    background-color: ${({ color }) => `var(--${color || "primary"})`};
    color: ${({ textColor, color }) => `var(--${textColor || color === "transparent" ? "black" : "white"})`};
    box-shadow: ${({ color }) => `var(--${color === "transparent" ? "none" : "0 4px 16px -8px var(--primary)" })`};
    transition: 0.4s;
    
    svg {
        fill: ${({ textColor, color }) => `var(--${textColor ||  color === "transparent" ? "black" : "white"})`};
    }
    
    :hover {
        color: ${({ color }) => `var(--${color === "transparent" ? "gray" : "white" })`};
        background-color: ${({ colorHover, color }) =>   `var(--${colorHover ||  color === "transparent" ? color : "primary-dark"})`};
        box-shadow: ${({ color }) => `var(--${color === "transparent" ? "none" : "0 6px 16px -6px var(--primary)" })`};
    }
`;

export const Link = styled(RouterLink)`
    color: ${({ color }) =>   `var(--${color || "primary"})`};
    border-bottom: 1px solid  ${({ color }) =>   `var(--${color || "primary"})`}    ;
    padding: 0px 2px;
    margin: 0px 2px;
    transition: 0.4s;
    
    :hover {
        color: ${({ color }) =>   `var(--${color || "primary-dark"})`};
        border-color: ${({ colorHover }) =>   `var(--${colorHover || "primary-dark"})`};
    }
`;

export const List = styled.ul`
    display: flex;
    ${({flexdir}) => `flex-direction: ${flexdir || "row" };`}
    ${({align}) => `align-items: ${align || "center"};`}
    ${({justify}) => `justify-content: ${justify || "center"};`}
    ${({ gap}) => `gap: ${gap || "1em"};`}
    list-style-type: none;
    padding-inline-start: 0;
`;

export const ListItem = styled.li`
    display: inline-flex;   
`;