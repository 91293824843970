import { SectionWrapper } from "./style";
import { Container } from "semantic-ui-react";
import Title from "assets/graphics/הבית הטרנסי/גלריית תמונותmdpi.svg";
import { Carousel } from "components/ui/Carousel";
import { Fade } from "react-awesome-reveal";
import { Loader } from "components/ui/Loader";
import { useStore } from "structure";
import { useEffect } from "react";
import { Media } from "components/ui/Media";
import { observer } from "mobx-react-lite";

export const Gallery = observer(() => {
  const { galleryStore } = useStore();
  const { isLoading, imgs } = galleryStore;

  console.log(imgs);

  useEffect(() => {
    galleryStore.fetchItems();
    // eslint-disable-next-line
  }, []);

  return (
    <SectionWrapper className="ls-gallery">
      <Container>
        <div>
          <Fade direction="right" triggerOnce={true}>
            <h2 className="major-title">
              <img src={Title} alt="גלריית תמונות" className="section-title" />
            </h2>
          </Fade>
          <div className="ls-images">
            {isLoading ? (
              <Loader inverted />
            ) : (
              <Carousel
                settings={{
                  slidesPerView: 1,
                  autoplay: { delay: 3500 },
                  effect: "cards",
                }}
                reverseItems={false}
              >
                {imgs.map((img, idx) => {
                  const imgSrc = img?.acf?.img;
                  if (!imgSrc) return null;
                  return (
                    <div key={idx} className="ls-carusel-item">
                      <div>
                        <Media id={imgSrc} />
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
      </Container>
    </SectionWrapper>
  );
});
