// import { useStore } from "./structure";
// import { useTranslation } from "react-i18next";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Home } from "./components/pages/Home";
import { Events } from "components/pages/Events";
import { Event } from "components/pages/Event";
import { About } from "components/pages/About";
import { PublicActions } from "components/pages/PublicActions";
import { TransHouse } from "components/pages/TransHouse";
import { Info } from "components/pages/Info";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/effect-cards";
import { WpPage } from "components/pages/WpPage";
import { NotFound } from "components/pages/NotFound";
import { Terms } from "components/pages/Terms";
import { Accessibility } from "components/pages/Accessibility";
import { Contact } from "components/pages/Contact";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route exaxt path="/events" element={<Events />} />
            <Route exaxt path="/event/:eventId" element={<Event />} />
            <Route exaxt path="/about" element={<About />} />
            <Route exaxt path="/public-actions" element={<PublicActions />} />
            <Route exaxt path="/trans-house" element={<TransHouse />} />
            <Route exaxt path="/info" element={<Info />} />
            <Route exaxt path="/terms" element={<Terms />} />
            <Route exaxt path="/accessibility" element={<Accessibility />} />
            <Route exaxt path="/info" element={<Info />} />
            <Route exaxt path="/contact" element={<Contact />} />
            <Route path={`/page/:pageSlug`} element={<WpPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
