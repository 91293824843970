import { NavLink } from "react-router-dom";
import { Wrapper } from "./style";
import { Icon } from "../Icon";

export const Nav = ({ pages }) => {
  if (!Array.isArray(pages)) return null;
  return (
    <Wrapper>
      {pages.map((page, idx) => {
        const haveNext = idx < pages.length - 1;
        return (
          <div key={idx}>
            <NavLink to={page.to}>{page.name}</NavLink>
            {haveNext && (
              <span>
                <Icon name="left" height="0.7em" />
              </span>
            )}
          </div>
        );
      })}
    </Wrapper>
  );
};
