import styled from "styled-components";
import { Card } from "semantic-ui-react";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  background-color: var(--blue);
  color: white;

  .ls-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 40px 24px 32px;

    h1 {
      font-size: 3em;
      text-align: center;

      .section-title {
        height: 36px;
        max-width: calc(100vw - 4em);
      }
    }
  }
`;

export const EventCard = styled(Card)``;

export const Caledar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: sticky;
  top: 78px;
  background: var(--blue);
  z-index: 1;
  padding: 12px;

  & > div:first-child {
    z-index: 10;
  }

  .select-month {
    display: flex;
    align-items: center;
    gap: 12px;
    border-borrom: 1px solid white;

    .ui.dropdown {
      background: transparent;
      border: none;
      color: white;
      width: 100px;
      min-width: 0;
      & > input.search {
        width: 100px;
        text-align: left;
      }
      & > .text {
        font-family: "Raleway", "OpenSans", sans-serif !important;
      }

      &.active {
        box-shadow: unset !important;
        & > input.search {
          background: white;
          color: white;
        }
        & > .divider.text {
          color: white;
        }
      }

      &.ui.active.search.dropdown input.search:focus + .text {
        color: #afc1f1 !important;
      }
    }
    & > button {
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: center;
      color: white;
      margin: 4px 16px 0;
      > span {
        padding: 2px 4px;
        border-radius: 4px;
      }

      &:nth-child(2) > span:last-child {
        background: white;
        color: var(--blue);
        font-weight: 600;
        min-width: 30px;
      }
    }
  }

  .select-day {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    justify-content: center;
  }

  @media (max-width: 640px) {
    .select-day {
      gap: 4px;
      & > button {
        font-size: 0.8em;
      }
    }
  }
`;

export const EventsWrapper = styled.div`
  margin-top: 80px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;

  & > div {
    margin-bottom: 48px;
  }

  .ls-date-title {
    background: var(--primary-soft);
    & > p {
      font-weight: 600;
      padding: 8px 16px;
    }
  }

  .ls-date-evenets {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 16px;
    padding: 8px 0;

    & > p {
      font-weight: 600;
    }

    .ui.card {
      margin: 12px 0;
      box-shadow: none;
      background: none;
      width: 276px;

      & > .content {
        width: 260px;
        padding: 0;

        > div {
          font-size: 1em;
          color: white;
        }
        > img,
        > .ui.placeholder {
          border-radius: 8px;
          margin-bottom: 8px;
          height: 200px;
          width: 200px;
          margin: 10px 0;
          animation: fadeIn 0.4s;
        }
        .header {
          font-size: 1.1em;
        }
        .meta {
          font-size: 0.8em;
        }
        .ui.button {
          padding-left: 0;
          float: left;
          font-size: 0.8em;
        }
      }
    }
  }

  .ls-days {
    p {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: -24px;
    }
  }

  .ls-day {
    min-width: 276px;
  }

  .ls-days-mobile {
    display: none;
    font-size: 18px;
    margin-left: 8px;
    font-weight: 700;
  }

  @media (max-width: 1600px) and (min-width: 1100px) {
    .ls-date-evenets {
      > p {
        font-size: 0.8em;
      }

      .ui.card {
        width: 180px !important;

        .content {
          width: 164px !important;
          font-size: 0.8em;

          .img {
            width: 164px !important;
            height: 164px !important;
          }
        }
      }
    }

    .ls-day {
      min-width: 180px !important;
    }
  }

  @media (max-width: 1100px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;

    .ls-days,
    .ls-days-empty {
      display: none;
    }

    .ls-days-mobile {
      display: inline-block;
    }

    .ls-day {
      margin-left: 20px;
    }
  }
`;
