import { SectionWrapper, HomeSection, LinkWithIcon } from "./style";
import Title1 from "assets/graphics/דף הבית/ברוכות הבאות.svg";
import Title2 from "assets/graphics/דף הבית/לטרנסיות ישראלmdpi.svg";
import Image from "assets/graphics/דף הבית/רקע דף הבית עם איורים.jpg";
import Painting1 from "assets/graphics/דף הבית/ז_אקלין שארלוט דיפרנואה (קוקסינל) ז״ל .png";
import Painting2 from "assets/graphics/דף הבית/יעל לביא.png";
import Painting3 from "assets/graphics/דף הבית/ננסי שניידר ז״ל.png";
import Painting4 from "assets/graphics/דף הבית/עדה ואלרי טל (לולה) ז״ל.png";
import { Icon } from "components/ui/Icon";
import { Fade } from "react-awesome-reveal";

export const Hero = () => {
  return (
    <SectionWrapper className="ls-hero">
      <HomeSection>
        <Fade
          className="ls-text"
          fraction={0.5}
          direction="right"
          triggerOnce={true}
        >
          <div>
            <h1>
              <img src={Title1} alt="ברוכות הבאות" />
              <img src={Title2} alt="לטרנסיות ישראל" />
            </h1>
            <LinkWithIcon to="/about">
              <span>למידע נוסף</span>
              <Icon name="left" size="1em" />
            </LinkWithIcon>
          </div>
        </Fade>
        <Fade
          className="ls-graphics-main"
          fraction={0.5}
          direction="left"
          triggerOnce={true}
          damping={0.2}
        >
          <div className="ls-bg">
            <img src={Image} alt="background" />
            <Fade
              className="ls-over"
              fraction={0.5}
              direction="left"
              triggerOnce={true}
              damping={0.2}
              cascade
              delay={1}
            >
              <ul>
                <li>
                  <div className="ls-name">
                    <span>ז'אקלין שארלוט דיפרנואה (קוקסינל) ז״ל </span>
                    <span> </span>
                  </div>
                  <img
                    src={Painting1}
                    alt="ז'אקלין שארלוט דיפרנואה (קוקסינל) ז״ל "
                  />
                </li>
                <li>
                  <div className="ls-name">
                    <span>יעל לביא</span>
                    <span> </span>
                  </div>
                  <img src={Painting2} alt="יעל לביא" />
                </li>
                <li>
                  <div className="ls-name">
                    <span>ננסי שניידר ז״ל</span>
                    <span> </span>
                  </div>
                  <img src={Painting3} alt="ננסי שניידר ז״ל" />
                </li>
                <li>
                  <div className="ls-name">
                    <span>עדה ואלרי טל (לולה) ז״ל"</span>
                    <span> </span>
                  </div>
                  <img src={Painting4} alt="עדה ואלרי טל (לולה) ז״ל" />
                </li>
              </ul>
            </Fade>
          </div>
        </Fade>
      </HomeSection>
    </SectionWrapper>
  );
};
