import { general } from "../endpoints";
import { makeObservable, observable, runInAction } from "mobx";

export class Items {
  items = [];
  isLoading = false;

  constructor(root) {
    this.rootStore = root;
    makeObservable(this, {
      items: observable.ref,
      isLoading: observable,
    });
  }

  async fetchItems(params, mediaFields = []) {
    runInAction(() => {
      this.isLoading = true;
    });
    let res = await this.getItems(params);
    if (Array.isArray(res)) {
      if (mediaFields.length > 0) {
        const promises = mediaFields.map((mediaField) => {
          return Promise.all(
            res.map((item, idx) => {
              const mediaId = item.acf[mediaField];
              if (mediaId) {
                return general.getMedia(mediaId);
              }
              return null;
            })
          );
        });
        await Promise.all(promises).then((mediaData) => {
          mediaFields.forEach((mediaField, fieldIdx) => {
            res.forEach((_, idx) => {
              res[idx].acf[mediaField] = mediaData[fieldIdx][idx].source_url;
            });
          });
        });
      }
      runInAction(() => {
        this.items = res;
        this.isLoading = false;
      });
    } else console.error("cant fetch items");

    runInAction(() => {
      this.isLoading = false;
    });
  }
}
