import { NavLink } from "react-router-dom";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import bg from "assets/icons/bg.svg";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw !important;
  overflow: hidden;
  .logo {
    border-bottom: 1px solid #a3afec;
  }
  h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    margin: 8px;
    color: #364488;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: var(--page-containter);
  margin: var(--page-margin);
`;

export const SectionHeader = styled.section`
  width: 100vw;
  height: 20em;
  background: center / cover
    url(https://transisrael.org.il/wp-content/uploads/2024/01/image650.png);
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 53vh;

  &:nth-child(2) {
    background: linear-gradient(45deg, #ed1f79, #ff7bac);
  }
  &:nth-child(3) {
    background-color: var(--primary);
    background-image: url(${bg});
    background-repeat: repeat;
    background-size: 48px;
  }
  &:nth-child(4) {
    background: linear-gradient(225deg, #ed1f79, #ff7bac);
  }
  &:nth-child(5) {
    background-color: var(--primary);
    background-image: url(${bg});
    background-repeat: repeat;
    background-size: 48px;
  }
  &:nth-child(7) {
    background: linear-gradient(135deg, #ed1f79, #ff7bac);
  }

  .ls-main {
    margin: 12vh 0;
    color: white;

    h2 {
      text-align: left;
    }

    .ls-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  .ls-goals .section-title {
    height: 2.1em;
  }

  .ls-team,
  .ls-goals {
    display: flex;
    flex-direction: column;
    gap: 24px;
    h2 {
      width: 20%;
      margin-left: 80%;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.ls-partners,
  &.ls-docs {
    h2 {
      text-align: left;
      width: max(20%, 300px);
      margin-left: 80%;

      @media (max-width: 800px) {
        text-align: right;
      }
    }
  }

  .ls-team > div {
    width: calc(100%);
    margin: 0 auto;
  }
  .ls-future .section-title {
    height: 2.6em;
  }
  .ls-about,
  .ls-future,
  .ls-docs {
    display: grid;
    grid-template-columns: 20% min(80%, 600px);
    gap: 24px;
  }

  .ls-team-item {
    padding-top: 12px;
    & > a {
      img,
      svg {
        transition: 0.3s;
        :hover {
          filter: drop-shadow(0px 4px 12px white);
        }
      }
    }
    & > div,
    & > a {
      display: flex;
      margin: 0 auto;
      width: min(200px, 90vw);
      flex-direction: column;
      direction: rtl;
      color: white;
      font-size: 1em;
      :hover {
        color: white;
      }

      img,
      .img {
        height: min(192px, 90vw);
        width: min(192px, 90vw);
        object-fit: cover;
        border-radius: 100%;
        border: 8px solid white;
      }
      > div {
        display: flex;
        flex-direction: column;
        margin: 8px;

        .header {
          display: flex;
          align-items: center;
          gap: 12px;
          p {
            font-weight: 800;
            letter-spacing: 0.7px;
          }

          svg {
            fill: white;
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }

  .ls-docs {
    .section-title {
      height: 2.1em;
      max-width: calc(100vw - 4em);
    }
    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-inline-start: unset;

      li > a {
        display: grid;
        grid-template-columns: 24px auto;
        align-items: center;
        gap: 8px;
        font-size: 0.9em;
        color: white;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--blue);
          border-radius: 100%;
          height: 1.2em;
          width: 1.2em;
          svg {
            height: 1em;
            width: 1em;
          }
        }
      }
    }
  }

  .ls-goal-item {
    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      background: var(--blue);
      width: min(200px, 90vw);
      border-radius: 12px;
      padding: 16px;
      margin: 12px auto;
      img,
      .img {
        width: 50%;
        margin: 0 auto;
        height: 120px;
        object-fit: contain;
        padding: 20px 0px;
      }
      p {
        min-height: 160px;
        text-align: center;
      }
    }
  }

  &.ls-partners {
    background: #333;
    color: white;
    min-height: auto;

    .ui.container {
      margin: 60px 0 80px;

      & > div {
        right: -2em;
      }
      img:not(.section-title) {
        max-width: 100%;
        height: 130px;
        object-fit: contain;
      }
    }
  }

  .section-title {
    height: 2.4em;
    max-width: calc(100vw - 4em);
  }

  @media (max-width: 992px) {
    .ls-about,
    .ls-future,
    .ls-docs {
      grid-template-columns: 1fr;

      h2 {
        text-align: right;
      }

      h3 {
        text-align: right;
        font-size: 1.8em;
      }
    }

    .ls-goals,
    .ls-team {
      h2 {
        text-align: right;
        max-width: 100%;
      }
    }
  }
`;

export const ButtonWithIcon = styled(Button)`
  &.ui.button {
    background: transparent;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    svg {
      transition: 0.3s;
    }

    &:hover svg {
      transform: translateX(-4px);
    }
  }
`;

export const LinkWithIcon = styled(NavLink)`
  background: transparent;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    transition: 0.3s;
  }

  &:hover {
    color: white;
    filter: drop-shadow(0px 0px 12px white);
    svg {
      transform: translateX(-4px);
    }
  }
`;
