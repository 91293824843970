import { Wrapper } from "./style";

export const NotFound = () => {
  return (
    <Wrapper>
      <h1>404</h1>
      <p>הדף המבוקש לא נמצא</p>
    </Wrapper>
  );
};
