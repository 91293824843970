import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  width: 90%;
  min-height: 400px;
  margin: 5em auto;

  h1 {
    font-weight: 800;
    font-size: 2em;
    color: var(--primary);
  }
`;
