import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useStore } from "structure";
import { Container } from "semantic-ui-react";
import { Loader } from "components/ui/Loader";
import { Carousel } from "components/ui/Carousel";
import { Media } from "components/ui/Media";
import { SectionWrapper } from "../About/style";
import Title from "assets/graphics/דף הבית/דברי איתנוmdpi.png";
import { Fade } from "react-awesome-reveal";
import { Icon } from "components/ui/Icon";

export const Team = observer(({ isTeamHash }) => {
  const { teamStore } = useStore();
  const { isLoading, mentors } = teamStore;
  const itemRef = useRef(null);

  useEffect(() => {
    teamStore.fetchItems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isTeamHash && itemRef) {
      scrollToTargetAdjusted(itemRef.current);
    }
    // eslint-disable-next-line
  }, [itemRef]);

  return (
    <SectionWrapper id="team" ref={itemRef}>
      <Container>
        <div className="ls-main ls-team">
          <Fade direction="right" triggerOnce={true}>
            <div className="cont">
              <h2>
                <img src={Title} alt="דברי איתנו" className="section-title" />
              </h2>
              <p style={{ width: "100%" }}>
                את מוזמנת לפנות אלינו בכל שאלה ובקשה לסיוע
              </p>
            </div>
          </Fade>
          {isLoading ? (
            <Loader inverted />
          ) : (
            <div>
              <Carousel>
                {mentors.map((item) => {
                  const name = item?.acf?.name || "";
                  const role = item?.acf?.role || "";
                  const link = item?.acf?.link || "";
                  const email = item?.acf?.email || "";
                  const img = item?.acf?.img || "";

                  return (
                    <div key={item.id} className="ls-carusel-item ls-team-item">
                      <WrapperElement link={link} email={email}>
                        <Media id={img} />
                        <div>
                          <Fade
                            direction="left"
                            cascade
                            damping={0.1}
                            triggerOnce={true}
                          >
                            <div className="header">
                              <p>{name}</p>
                              {link && (
                                <span>
                                  <Icon name="whatsapp" />
                                </span>
                              )}
                              {email && (
                                <a
                                  href={`mailto:${email}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Icon name="email" />
                                </a>
                              )}
                            </div>

                            <p>{role}</p>
                          </Fade>
                        </div>
                      </WrapperElement>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
      </Container>
    </SectionWrapper>
  );
});

function scrollToTargetAdjusted(element) {
  var headerOffset = 60;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.scrollY - headerOffset;
  console.log({ elementPosition, scrollY: window.scrollY, headerOffset });
  setTimeout(() => {
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }, 500);
}

const WrapperElement = ({ link, email, children }) => {
  if (link) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
  if (email) {
    return (
      <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
  return <div>{children}</div>;
};
