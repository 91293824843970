import { Wrapper } from "./style";
import { Loader as SemanticLoader } from "semantic-ui-react";

export const Loader = (props) => {
  return (
    <Wrapper>
      <SemanticLoader active inline="centered" {...props} />
    </Wrapper>
  );
};
