import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import bg from "assets/icons/bg.svg";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo {
    border-bottom: 1px solid #a3afec;
  }
  h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    margin: 8px;
    color: #364488;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: var(--page-containter);
  margin: var(--page-margin);
`;

export const SectionHeader = styled.section`
  width: 100vw;
  height: 20em;
  background: center / cover
    url(https://transisrael.org.il/wp-content/uploads/2024/01/WhatsApp-Image-2022-05-15-at-09.59.16.jpeg);
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 53vh;
  overflow: hidden;

  &:nth-child(2) {
    background: white;
    .ls-main {
      color: var(--primary);
    }
  }
  &:nth-child(3) {
    background-color: var(--primary);
    background-image: url(${bg});
    background-repeat: repeat;
    background-size: 48px;
  }
  &:nth-child(4) {
    background-color: var(--primary-soft);
    background-image: linear-gradient(
        to top,
        transparent 20%,
        var(--primary-soft) 60%
      ),
      url(${bg});
    background-repeat: repeat;
    background-size: 48px;
  }

  .ls-main {
    margin: 56px 0 70px;
    color: white;

    h2 {
      text-align: left;
      font-size: 3em;
      font-weight: 900;
    }

    .ls-content {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  .ls-articles {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h2 {
      max-width: 30%;
    }
  }

  .ls-about,
  .ls-papers {
    display: grid;
    grid-template-columns: 20% min(80%, 600px);
    gap: 24px;

    h2 {
      font-size: 2em;
    }
    h3 {
      font-size: 1.5em;
      text-align: left;
    }
  }

  .ls-articles-item {
    & > a {
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      align-items: flex-start;
      direction: rtl;
      width: min(200px, 90vw);

      img,
      .img {
        height: min(200px, 90vw);
        width: min(200px, 90vw);
        object-fit: cover;
        border-radius: 12px;
      }
      > div {
        display: flex;
        flex-direction: column;
        margin: 8px 0;
        font-size: 1em;
        color: white;

        p:first-child {
          font-weight: 700;
          font-size: 1.2em;
          letter-spacing: 0.7px;
          margin-bottom: 4px;
        }
      }
    }
  }

  .ls-papers {
    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-inline-start: unset;
      margin: 0;

      li > a {
        display: grid;
        grid-template-columns: 24px auto;
        align-items: center;
        gap: 8px;
        font-size: 0.9em;
        color: white;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          border-radius: 100%;
          height: 1.2em;
          width: 1.2em;
          color: var(--primary);
          svg {
            height: 1em;
            width: 1em;
          }
        }
      }
    }
  }

  .ls-articles h2 {
    text-align: right;
    font-size: 2.4em;
    max-width: 100%;
  }

  .section-title {
    max-height: 1.2em;
    max-width: calc(100vw - 4em);
  }

  @media (max-width: 992px) {
    .ls-about,
    .ls-papers {
      grid-template-columns: 1fr;

      h2 {
        text-align: right;
        font-size: 2.4em;
      }

      h3 {
        text-align: right;
        font-size: 1.8em;
      }
    }
  }
`;

export const ButtonWithIcon = styled(Button)`
  &.ui.button {
    background: transparent;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    svg {
      transition: 0.3s;
    }

    &:hover svg {
      transform: translateX(-4px);
    }
  }
`;

export const LinkWithIcon = styled(Link)`
  background: transparent;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    transition: 0.3s;
  }

  &:hover {
    color: white;
    filter: drop-shadow(0px 0px 12px white);
    svg {
      transform: translateX(-4px);
    }
  }
`;
