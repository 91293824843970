
import { goals } from "../endpoints";
import { Items } from "./Items";
import { makeObservable, computed } from "mobx";

export class Goals extends Items {
    constructor() {
        super();
        this.getItems = goals.getGoals;
        makeObservable(this, {
            goals: computed,
        })
    }

    get goals() {
        return Array.isArray(this.items) ? this.items : [];
    }



}