import { useStore } from "structure";
import { SectionWrapper } from "./style";
import { Container } from "semantic-ui-react";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Icon } from "components/ui/Icon";
import { Fade } from "react-awesome-reveal";
import { useSearchParams } from "react-router-dom";
import { Loading } from "components/shared/Loading";

const makeInfo = (arr) => {
  let res = {};
  arr.forEach((element) => {
    if (res[element.category])
      res[element.category].push({
        a: element.a,
        q: element.q,
        slug: element.slug,
      });
    else
      res[element.category] = [
        { a: element.a, q: element.q, slug: element.slug },
      ];
  });
  return res;
};

export const QnA = observer(() => {
  const { infoStore } = useStore();
  const { isLoading, qna } = infoStore;
  const info = makeInfo(qna);

  useEffect(() => {
    infoStore.fetchItems();
    // eslint-disable-next-line
  }, []);

  return (
    <SectionWrapper>
      <Loading isLoading={isLoading} />
      <Container>
        <div className="ls-main">
          {Object.entries(info).map(([sectionName, section], idx) => (
            <div className="ls-qna-section" key={idx}>
              <Fade cascade delay={100 * idx} triggerOnce={true}>
                <h2>{sectionName}</h2>
              </Fade>
              <Fade
                direction="up"
                triggerOnce={true}
                cascade
                damping={0.1}
                fraction={0.2}
                delay={200 + 100 * idx}
              >
                <div className="ls-qna-items">
                  {section.map((item, idx) => (
                    <QnaItem data={item} key={idx} />
                  ))}
                </div>
              </Fade>
            </div>
          ))}
        </div>
      </Container>
    </SectionWrapper>
  );
});

const QnaItem = ({ data }) => {
  const [searchParams] = useSearchParams();
  const isTrgeted = searchParams.get("q") === data.slug;
  const [isOpen, setIsOpen] = useState(isTrgeted);
  const itemRef = useRef(null);

  useEffect(() => {
    if (isTrgeted && itemRef) {
      scrollToTargetAdjusted(itemRef.current);
    }
    // eslint-disable-next-line
  }, [itemRef]);

  return (
    <div
      className={"ls-qna-item" + (isTrgeted ? " selected" : "")}
      ref={itemRef}
    >
      <button onClick={() => setIsOpen(!isOpen)}>
        <Icon name={isOpen ? "minus" : "plus"} />
        <span>{data.q}</span>
      </button>
      <pre
        className={isOpen ? "ls-open" : "ls-close"}
        dangerouslySetInnerHTML={{ __html: data.a }}
      />
    </div>
  );
};

function scrollToTargetAdjusted(element) {
  var headerOffset = 120;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.scrollY - headerOffset;

  setTimeout(() => {
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }, 1000);
}
