import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "structure";
import * as S from "./style";
import { Container } from "semantic-ui-react";
import { Loader } from "components/ui/Loader";
import { useParams } from "react-router-dom";
import { Nav } from "components/ui/Nav";
import { Media } from "components/ui/Media";
import { Icon } from "components/ui/Icon";
import { Input } from "components/ui/form/Input";
import { months } from "../Events/eventHelper";
import { Button } from "components/ui/style";
import { Form } from "react-final-form";
import {
  composeValidators,
  email,
  minValue,
  required,
} from "components/ui/form/validators";
import { general } from "structure/endpoints";
import { Fade } from "react-awesome-reveal";

export const Event = observer(() => {
  const { eventsStore } = useStore();
  const { eventId } = useParams();
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isSubmited, setIsSubmited] = useState(false);
  const { isLoading, events } = eventsStore;

  useEffect(() => {
    eventsStore.fetchSpesificEvent(eventId).then(() => {
      setIsFirstLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  if (isFirstLoading || isLoading) return <Loader inverted />;

  const event = events.find((ev) => ev.id === Number(eventId));

  if (!event) return <div>NO EVENT FOUND</div>;
  const title = event?.title?.rendered || "";
  const date = toDateString(event?.acf?.date);
  const time = event?.acf?.time || "";
  const note = event?.acf?.note;
  const desc = event?.acf?.desc || "";
  const price = event?.acf?.price || "ללא תשלום";
  const paymentLink = event?.acf?.paymentLink;
  const mainImg = event?.acf?.mainImg || 103;
  const imgs = [event?.acf?.img1, event?.acf?.img2].filter((item) => item);

  const pages = [
    {
      name: "ראשי",
      to: "/",
    },
    {
      name: "אירועים",
      to: "/events",
    },
    {
      name: title,
      to: `/event/${eventId}`,
    },
  ];

  const onSubmit = async (data) => {
    await general.sendEmail({ אירוע: title, תאריך: date, ...data });
    setIsSubmited(true);
  };

  return (
    <S.Wrapper>
      <Container>
        <Nav pages={pages} />
        <div className="ls-conteiner">
          <div className="ls-img">
            <Fade triggerOnce={true}>
              <Media id={mainImg} />
            </Fade>
          </div>
          <div className="ls-content">
            <div>
              <Fade direction="left" cascade damping={0.1} triggerOnce={true}>
                <h1 className="ls-title">{title}</h1>
                <h2 className="ls-date">{date}</h2>
                <p
                  className="ls-desc"
                  dangerouslySetInnerHTML={{
                    __html: desc.replaceAll("\r\n", "<br />"),
                  }}
                />{" "}
              </Fade>
            </div>
            <Fade direction="left" cascade damping={0.1} triggerOnce={true}>
              <div className="ls-meta">
                {note && (
                  <div>
                    <Icon name="squer" height="0.8em" width="0.8em" />{" "}
                    <p>{note}</p>
                  </div>
                )}
                <div>
                  <Icon name="time" height="0.8em" width="0.8em" />{" "}
                  <p>{time}</p>
                </div>
              </div>
            </Fade>
            {imgs.length > 0 && (
              <div className="ls-gallery">
                {imgs.map((img, idx) => (
                  <div key={`${img}-${idx}`}>
                    <Media id={img} />
                  </div>
                ))}
              </div>
            )}
            <Fade direction="up" cascade damping={0.1} triggerOnce={true}>
              {paymentLink ? (
                <div className="ls-sell-tickets">
                  <p>מחיר: {price}</p>
                  <Button as="a" href={paymentLink} target="_blank">
                    לרכישת כרטיסים
                  </Button>
                </div>
              ) : (
                <div className="ls-form-reg">
                  {isSubmited ? (
                    <div className="ls-success-message">
                      <Icon name="success" />
                      <p>מחכות לראות אותך!</p>
                    </div>
                  ) : (
                    <>
                      <p>אנא מלאי את הטופס המצורף</p>
                      <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit, submitting }) => (
                          <S.FormWrapper onSubmit={handleSubmit}>
                            <Fade
                              cascade
                              damping={0.1}
                              triggerOnce={true}
                              delay={1000}
                            >
                              <Input
                                name="שם"
                                label="שם"
                                validator={required}
                                disabled={submitting}
                              />
                              <Input
                                name="מייל"
                                type="email"
                                label="מייל"
                                validator={composeValidators(required, email)}
                                disabled={submitting}
                              />
                              <Input
                                name="טלפון"
                                type="phone"
                                label="טלפון"
                                validator={required}
                                disabled={submitting}
                              />
                              <Input
                                name="משתתפים"
                                type="number"
                                label="משתתפים"
                                min="1"
                                validator={composeValidators(
                                  required,
                                  minValue(1)
                                )}
                                disabled={submitting}
                              />
                              <p>מחיר: {price}</p>

                              <Button
                                type="submit"
                                isLoading={submitting}
                                disabled={submitting}
                              >
                                אני מגיעה
                              </Button>
                            </Fade>
                          </S.FormWrapper>
                        )}
                      />
                    </>
                  )}
                </div>
              )}
            </Fade>
          </div>
        </div>
      </Container>
    </S.Wrapper>
  );
});

const toDateString = (date) => {
  if (!date) return "-";
  return `${date.slice(6, 8)} ב${
    months[Number(date.slice(4, 6)) - 1]
  } ${date.slice(0, 4)}`;
};
