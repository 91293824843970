
import i18n from "i18next";
import {  initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "admin": "Admin",
        },
      },
      he: {
        translation: {
              "admin": "מנהל",
              "Full use": "שימוש מלא",
              "Partial use": "שימוש חלקי",
              "Balance left": "לאחר שימוש",
              "Use": "רכישה",
              "Pay": "תשלום",
              "Expire date": "תוקף",
              "Balance": "יתרה",
              "Card number": "מספר כרטיס",
              "Filter": "סינון",
              "All Cards": "כל הכרטיסים",
          "redults": "תוצאות",
          "Email and password not match": "מייל או סיסמא שגויים",
          "Register": "הרשמה",
              "Don't have an account? go to" : "עוד אין לך חשבון?",
              "Submit" : "התחבר",
              "Password" : "סיסמא",
              "Email" : "מייל",
              "Login" : "התחברות",
              "I have an account, go to" : "כבר יש לי חשבון, ",
          "Password Confirmation": "אימות סיסמא",
          "Name": "שם",
              "ILS": "שקלים",
          "USD": "דולר",
          "Language": "שפה",
          "Settings": "הגדרות",
          "Currency": "מטבע",
          "Save settings": "שמירת שינוים",
          "Sign out": "התנתקות",
              "Add Card": "הוספת כרטיס",
              "Select card type": "בחר סוג כרטיס",
              "Card type": "סוג כרטיס",
              "Amount": "סכום",
              "Add this card": "יצירת כרטיס",
          "Empty Cards": "כרטיסים ריקים",
          "Cards": "ריקים",
          "Empty": "כרטיסי",
          "results": "תוצאות",
          "Search": "חיפוש",
          "Create new card type": "יצירת סוג כרטיס חדש",
          "Add new type": "סוג כרטיס חדש",
          "Cancel": "ביטול",
          "Card color": "צבע כרטיס",
          "Card type name": "שם סוג כרטיס",
          "Card shops link": "קישור לרשימת חנויות",
          "Card balance link": "קישור לבדיקת יתרה",
          "Edit card": "עריכת כרטיס",
          "View history": "היסטורית שימוש",
          "History": "היסטוריה",
          "Date": "תאריך",
          "Close": "סגירה",
          "Edit Card": "עריכת כרטיס",
          "Update": "עדכון",
          "Shops list": "רשימת חנויות",
          "Check balance": "בירור יתרה",
          "Card will move to used card list, are you sure you want to set the card as full used?": "השובר יועבר לרשימת השוברים הממומשים, האם אתה בטוח שאתה מעוניין לסמן את השובר כשובר שנוצל במלואו?",
          "Yes": "כן",
          "Not yet": "עוד לא",
          "Restore": "שחזור כרטיס",
          "Set balance": "עדכן סכום",
          "": "",
        },
      },
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, 
    },
  });