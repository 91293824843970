import { team } from "../endpoints";
import { Items } from "./Items";
import { makeObservable, computed } from "mobx";

export class Team extends Items {
  constructor() {
    super();
    this.getItems = team.getTeam;
    makeObservable(this, {
      team: computed,
    });
  }

  get team() {
    return Array.isArray(this.items) ? this.items : [];
  }

  get mentors() {
    return this.team.filter((item) => item?.acf?.isMentor);
  }

  get nonMentors() {
    return this.team.filter((item) => !item?.acf?.isMentor);
  }
}
