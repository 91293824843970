
import { gallery } from "../endpoints";
import { Items } from "./Items";
import { makeObservable, computed } from "mobx";

export class Gallery extends Items {
    constructor() {
        super();
        this.getItems = gallery.getImgs;
        makeObservable(this, {
            imgs: computed,
        })
    }

    get imgs() {
        return Array.isArray(this.items) ? this.items : [];
    }



}