import styled from "styled-components";
import bg from "assets/icons/bg.svg";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  min-height: calc(100vh - 280px);
  background-color: var(--primary);
  background-image: url(${bg});
  background-repeat: repeat;
  background-size: 80px;

  h1 {
    font-weight: 800;
    font-size: 2em;
    color: white;
  }

  .ls-team {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .cont {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 2em;
    }
    h2 {
      text-align: right;
      > img {
        height: 4em;
      }
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ls-team > div {
    width: 100%;
    margin: 0 auto;
  }

  .ls-team-item {
    padding-top: 12px;
    & > a {
      img,
      svg {
        transition: 0.3s;
        :hover {
          filter: drop-shadow(0px 4px 12px white);
        }
      }
    }
    & > div,
    & > a {
      display: flex;
      margin: 0 auto;
      width: min(200px, 90vw);
      flex-direction: column;
      direction: rtl;
      color: white;
      font-size: 1em;
      :hover {
        color: white;
      }

      img,
      .img {
        height: min(192px, 90vw);
        width: min(192px, 90vw);
        object-fit: cover;
        border-radius: 100%;
        border: 8px solid white;
      }
      > div {
        display: flex;
        flex-direction: column;
        margin: 8px;

        .header {
          display: flex;
          align-items: center;
          gap: 12px;
          p {
            font-weight: 800;
            letter-spacing: 0.7px;
          }

          svg {
            fill: white;
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .ls-team {
      h2 {
        text-align: right;
        max-width: 100%;
      }
    }
  }

  .moreBtns {
    display: flex;
    margin-top: -40px;
    padding: 0 0 80px;
    > a {
      background: white;
      padding: 12px 24px;
      border-radius: 8px;
      color: var(--primary);
      font-weight: bold;
      font-size: 1.6em;
    }
  }
`;
