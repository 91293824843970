import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "structure";
import { Container } from "semantic-ui-react";
import { Loader } from "components/ui/Loader";
import { Carousel } from "components/ui/Carousel";
import { Media } from "components/ui/Media";
import { SectionWrapper } from "./style";
import { Fade } from "react-awesome-reveal";
import Title from "assets/graphics/פעילות ציבורית/עלינו בתקשורתmdpi.svg";

export const Articles = observer(() => {
  const { articlesStore } = useStore();
  const { isLoading, articles } = articlesStore;

  useEffect(() => {
    articlesStore.fetchItems();
    // eslint-disable-next-line
  }, []);

  return (
    <SectionWrapper id="articles">
      <Container>
        <div className="ls-main ls-articles">
          <Fade direction="right" triggerOnce={true} fraction={0.2}>
            <h2>
              <img src={Title} alt="עלינו בתקשורת" className="section-title" />
            </h2>
          </Fade>

          {isLoading ? (
            <Loader inverted />
          ) : (
            <Carousel>
              {articles.map((item) => {
                const title = item?.title?.rendered || "";
                const from = item?.acf?.from || "";
                const date = getDate(item?.acf?.date || "");
                const link = item?.acf?.link || "/";
                const img = item?.acf?.img || "";

                return (
                  <div
                    key={item.id}
                    className="ls-carusel-item ls-articles-item"
                  >
                    <a href={link} target="_blank" rel="noreferrer">
                      <Media id={img} />
                      <div>
                        <p>{title}</p>
                        <p>{from}</p>
                        <p>{date}</p>
                      </div>
                    </a>
                  </div>
                );
              })}
            </Carousel>
          )}
        </div>
      </Container>
    </SectionWrapper>
  );
});

const getDate = (date) => {
  if (!date) return "-";
  return date.slice(6, 8) + "." + date.slice(4, 6) + "." + date.slice(0, 4);
};
