import { AboutSection } from "./AboutSection";
import { FutureSecition } from "./FutureSecition";
import { Team } from "./Team";
import { Docs } from "./Docs";
import { SectionHeader, Wrapper } from "./style";
import { Goals } from "./Goals";
import { Partners } from "./Partners";
import { Fade } from "react-awesome-reveal";

export const About = () => {
  const isTeamHash = false;

  return (
    <Wrapper>
      <Fade duration={isTeamHash ? 0 : 1000} triggerOnce={true}>
        <SectionHeader />
      </Fade>
      <AboutSection isDisabled={isTeamHash} />
      <FutureSecition isDisabled={isTeamHash} />
      <Goals isDisabled={isTeamHash} />
      <Team isTeamHash={isTeamHash} />
      <Partners isDisabled={isTeamHash} />
      <Docs isDisabled={isTeamHash} />
    </Wrapper>
  );
};
