import styled from "styled-components";

export const Wrapper = styled.div`
  .ui.sidebar {
    background: var(--blue);

    .ls-close {
      position: relative;
      top: 24px;
      right: 24px;
      color: white;

      svg {
        height: 32px;
        width: 32px;
      }
    }
  }

  .ui.ui.secondary.vertical.menu {
    width: 100%;
    padding: 30px;
    margin-top: 60px;
    & > a.item {
      margin: 8px 0;
      font-size: 1.37em;
      border-radius: 8px !important;
      padding: 0.6em 0.8em;
      color: white;
      transition: 0.4s;
      text-align: right;

      &.active {
        font-weight: 700;
        background: white;
        color: var(--blue);

        &:hover {
          filter: none;
          background: white;
        }
      }

      &:hover {
        opacity: 1;
        filter: drop-shadow(0px 0px 8px white) drop-shadow(0px 0px 16px white);
        background: none;
      }
    }
  }
`;
