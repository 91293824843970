import { SectionWrapper } from "./style";
import { Container } from "semantic-ui-react";
import { Carousel } from "components/ui/Carousel";
import { useStore } from "structure";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Media } from "components/ui/Media";
import { Fade } from "react-awesome-reveal";

export const Partners = observer(({ isDisabled }) => {
  const { partnersStore } = useStore();
  const { partners } = partnersStore;

  useEffect(() => {
    partnersStore.fetchItems();
    // eslint-disable-next-line
  }, []);

  return (
    <SectionWrapper className="ls-partners">
      <Container>
        <Fade
          duration={isDisabled ? 0 : 1000}
          direction="right"
          triggerOnce={true}
        >
          <h2>שותפים לדרך</h2>
        </Fade>
        <br />
        <Carousel
          settings={{
            initialSlide: 0,
            navigation: false,
            autoplay: {
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
              waitForTransition: false,
            },
            loop: true,
          }}
        >
          {partners.map((partner, idx) => {
            if (partner?.acf?.link)
              return (
                <a
                  href={partner?.acf?.link}
                  target="_blank"
                  rel="noreferrer"
                  key={partner.id}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Media id={partner?.acf?.logo} />
                  </div>
                </a>
              );
            return (
              <div key={partner.id}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Media id={partner?.acf?.logo} />
                </div>
              </div>
            );
          })}
        </Carousel>
      </Container>
    </SectionWrapper>
  );
});
