import { Loader } from "components/ui/Loader";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "structure";

const site_content = [
  "תקנון האתר",
  "מדיניות פרטיות",
  "טרנסיות ישראל העמותה",
  "הצהרת נגישות",
  "פעילות ציבורית",
  "הבית הטרנסי",
  "חזון",
  "עלינו",
];

export const WpPage = observer(() => {
  const { pageSlug } = useParams();
  const { wpPages } = useStore();
  const page = wpPages.find((page) => page.slug === pageSlug);

  if (!page || site_content.includes(page.title?.rendered)) {
    return <Loader />;
  }

  return (
    <section dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
  );
});
