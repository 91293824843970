import { SectionWrapper, HomeSection, LinkWithIcon } from "./style";
import Image from "assets/graphics/דף הבית/דגל.png";
import Title from "assets/graphics/דף הבית/נעים להכירmdpi.svg";
import { Icon } from "components/ui/Icon";
import { Fade } from "react-awesome-reveal";

export const About = () => {
  return (
    <SectionWrapper className="ls-hero">
      <HomeSection
        className="ls-about-in-hero"
        color="var(--blue)"
        btnBgColor={"white"}
      >
        <Fade
          className="ls-graphics"
          fraction={0.5}
          direction="right"
          triggerOnce={true}
        >
          <img src={Image} alt="דגל" />
        </Fade>
        <Fade
          className="ls-text"
          fraction={0.5}
          direction="left"
          triggerOnce={true}
        >
          <div>
            <h1>
              <span>
                <img src={Title} alt="נעים להכיר" className="major-title" />
              </span>
              <span className="text">
                עמותת <b>טרנסיות ישראל</b>
              </span>
              <span className="text">מייסדת ומפעילת</span>
              <span className="ls-content-logo">
                <Icon name="transHouse" />
              </span>
            </h1>
            <LinkWithIcon to="/trans-house">
              <span>בואו להכיר אותנו</span>
              <Icon name="left" size="1em" />
            </LinkWithIcon>
          </div>
        </Fade>
      </HomeSection>
    </SectionWrapper>
  );
};
