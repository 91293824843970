const months = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];

export const currentMonth = () => {
    const today = new Date();
    const month = today.getMonth();
    return months[month];
}

export const currentYear = () => {
    const today = new Date();
    return today.getFullYear();
}