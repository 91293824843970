import { qna as qnaEndpoint } from "../endpoints";
import { Items } from "./Items";
import { makeObservable, computed } from "mobx";

export class QnA extends Items {
  constructor() {
    super();
    this.getItems = qnaEndpoint.getQna;
    makeObservable(this, {
      qna: computed,
    });
  }

  get qna() {
    return Array.isArray(this.items)
      ? this.items.map((item) => ({ ...item.acf, slug: item.slug }))
      : [];
  }
}
