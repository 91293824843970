import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 800;
    font-size: 4em;
    margin: 8px;
    color: var(--primary);
  }
  p {
    font-weight: 400;
    font-size: 1.5em;
  }
`;
