import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "structure";
import { Container } from "semantic-ui-react";
import { Loader } from "components/ui/Loader";
import { Icon } from "components/ui/Icon";
import { SectionWrapper } from "./style";
import { Fade } from "react-awesome-reveal";
import Title from "assets/graphics/פעילות ציבורית/ניירות עמדהmdpi.svg";

export const Papers = observer(() => {
  const { papersStore } = useStore();
  const { isLoading, papers } = papersStore;

  useEffect(() => {
    papersStore.fetchItems(null, ["file"]);
    // eslint-disable-next-line
  }, []);

  return (
    <SectionWrapper id="papers">
      <Container>
        <div className="ls-main ls-papers">
          <Fade direction="right" triggerOnce={true}>
            <h2>
              <img src={Title} alt="ניירות עמדה" className="section-title" />
            </h2>
          </Fade>

          <div className="ls-content">
            {isLoading ? (
              <Loader inverted />
            ) : (
              <Fade direction="left" cascade damping={0.1} triggerOnce={true}>
                <ul>
                  {papers.map((paper) => {
                    const name = paper.title.rendered;
                    const file = paper.acf.file;
                    return (
                      <li key={paper.id}>
                        <a href={file} target="_blank" rel="noreferrer">
                          <Icon name="download" size="1em" />
                          <span>{name}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Fade>
            )}
          </div>
        </div>
      </Container>
    </SectionWrapper>
  );
});
