import { Fade } from "react-awesome-reveal";
import { QnA } from "./QnA";
import { SectionHeader, Wrapper } from "./style";

export const Info = () => {
  return (
    <Wrapper>
      <Fade direction="down" triggerOnce={true}>
        <SectionHeader />
      </Fade>
      <QnA />
    </Wrapper>
  );
};
