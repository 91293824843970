import { AboutSection } from "./AboutSection";
import { Events } from "./Events";
import { Gallery } from "./Gallery";
import { Wrapper } from "./style";

export const TransHouse = () => {
  return (
    <Wrapper>
      <AboutSection />
      <Events />
      <Gallery />
    </Wrapper>
  );
};
