import styled from "styled-components";

export const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  padding-top: 48px;
  background-color: var(--black);
  color: var(--white);

  .ls-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    a:hover > div > svg {
        transition: 0.2s;
    }

    a:hover > div > svg {
        fill: var(--primary) ;
    }

    .ls-row-1 {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 8px;

      span {
        font-size: 1em;
      }
    }

    .ls-sitemap {
            display: flex;
    align-items: center;
    gap: 8px;
        a {
                font-weight: 700;   
                color: var(--white);

                :hover {
                    color: var(--primary);
                }
            }
    }

    


  .ls-rights {
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin-bottom: 48px;
  }

  @media (max-width: 660px) {
    .ls-main .ls-row-2 {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }

    .ls-main .ls-site-map {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  gap: 16px;

  a svg {
    height: 24px;
    width: 24px;
    fill: var(--white);
  }
`;
