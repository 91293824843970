import { SectionWrapper, HomeSection } from "./style";
import Title from "assets/graphics/דף הבית/צרו קשרmdpi.svg";
import Image from "assets/graphics/דף הבית/מפה.png";
import { Icon } from "components/ui/Icon";
import { Fade } from "react-awesome-reveal";

export const Map = () => {
  return (
    <SectionWrapper className="ls-hero">
      <HomeSection color="#ff7bac">
        <Fade
          className="ls-text"
          fraction={0.5}
          direction="right"
          triggerOnce={true}
        >
          <div className="contact-details">
            <h1>
              <img src={Title} alt="צרו קשר" />
            </h1>
            <div className="ls-contact">
              <Fade direction="left" fraction={0.8} triggerOnce={true}>
                <a
                  href="https://maps.app.goo.gl/c6YJG3oETjhbk7PK7"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="location_s" />
                  <span>רחוב פיין 1 תל-אביב</span>
                </a>
                <a href="tel:0523005280" target="_blank" rel="noreferrer">
                  <Icon name="phone_s" />
                  <span>052-300-5280</span>
                </a>
                <a
                  href="mailto:israeltrans.ngo@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="email_s" />
                  <span>israeltrans.ngo@gmail.com</span>
                </a>
                <a
                  href="https://www.facebook.com/transisraelngo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="facebook_s" />
                  <span>טרנסיות ישראל</span>
                </a>
                <a
                  href="https://www.instagram.com/trans.israel"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="instegram_s" />
                  <span>trans.israel</span>
                </a>
              </Fade>
            </div>
          </div>
        </Fade>
        <Fade
          className="ls-graphics"
          fraction={0.5}
          direction="left"
          triggerOnce={true}
        >
          <img src={Image} alt="מפה" />
        </Fade>
      </HomeSection>
    </SectionWrapper>
  );
};
