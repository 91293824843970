import { observer } from "mobx-react-lite";
import { Container } from "semantic-ui-react";
import * as S from "./style";
import { Menu } from "components/ui/Menu";
import { Icon } from "components/ui/Icon";
import { NavLink } from "react-router-dom";

export const Header = observer(() => {
  return (
    <S.Wrapper>
      <Container>
        <div className="ls-header">
          <div>
            <Menu />
            {/* <Dropdown options={langOptions} defaultValue={0}></Dropdown> */}
            <NavLink className="ls-logo" to="/">
              <Icon name="logo" />
            </NavLink>
          </div>
          <div>
            <div className="ls-support">
              <NavLink to="/contact">פניה לצוות</NavLink>
            </div>

            <a
              className="ls-primary-button"
              href="https://www.jgive.com/new/he/ils/collect/donation-targets/115011/amount?fbclid=IwAR2YUvoMC0pqQ09Rk5cLiC34hzC8OyT__vCT6HGA4gdOTPg2DfUyFKZFLXQ"
              target="_blank"
              rel="noreferrer"
            >
              תמכו בנו
              <Icon name="heart" />
            </a>
            <S.SocialWrapper>
              {/* <a href="/" target="_blank" rel="noreferrer">
                <Icon name="tiktok" />
              </a> */}
              <a
                href="https://www.instagram.com/trans.israel"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="instegram" />
              </a>
              <a
                href="https://www.facebook.com/transisraelngo"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="facebook" />
              </a>
              <a
                href="mailto:ceo.israeltrans@org.il"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="email" />
              </a>
              <a href="tel:0523005280" target="_blank" rel="noreferrer">
                <Icon name="phone" />
              </a>
            </S.SocialWrapper>
          </div>
        </div>
      </Container>
    </S.Wrapper>
  );
});
