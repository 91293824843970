import * as S from "./style";
import { Icon } from "../Icon";
import { Children, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Autoplay, EffectCards } from "swiper/modules";

export const Carousel = ({ children, settings = {} }) => {
  const [myIndex, setMyIndex] = useState(0);
  const swiperRef = useRef();
  const childrenArray = Children.toArray(children);

  useEffect(() => {
    setTimeout(() => {
      if (swiperRef && settings.autoplay) {
        swiperRef.current?.swiper?.slideTo(1);
      }
    }, 2500);
    // eslint-disable-next-line
  }, [swiperRef]);

  return (
    <S.Wrapper>
      <div className="ls-swiper">
        <Swiper
          slidesPerView={"auto"}
          dir="rtl"
          modules={[Navigation, Autoplay, EffectCards]}
          onSlideChange={() => setMyIndex(myIndex + 1)}
          {...settings}
          ref={swiperRef}
        >
          {settings.navigation !== false && <Arrows index={myIndex} />}

          {childrenArray.map((child, index) => {
            return <SwiperSlide key={index}>{child}</SwiperSlide>;
          })}
        </Swiper>
      </div>
    </S.Wrapper>
  );
};

function Arrows({ index }) {
  const swiper = useSwiper();
  const [isDisabled, setIsDisabled] = useState({
    prev: swiper.virtualSize - swiper.width === swiper.translate,
    next: swiper.translate === 0,
  });

  useEffect(() => {
    setIsDisabled({
      prev: swiper.virtualSize - swiper.width === swiper.translate,
      next: swiper.translate === 0,
    });
    // eslint-disable-next-line
  }, [index]);

  const Move = (to) => {
    if (to === "next") swiper.slidePrev();
    if (to === "prev") swiper.slideNext();
  };

  if (swiper.virtualSize === swiper.width) return null;
  return (
    <div className="ls-arrows">
      <button
        className={isDisabled.next ? "swiper-button-disabled" : ""}
        onClick={() => Move("next")}
      >
        <Icon name="right" size="1.4em" />
      </button>
      <button
        className={isDisabled.prev ? "swiper-button-disabled" : ""}
        onClick={() => Move("prev")}
      >
        <Icon name="left" size="1.4em" />
      </button>
    </div>
  );
}
