import { observer } from "mobx-react-lite";
import * as S from "./style";
import { Input as SemanticInput } from "semantic-ui-react";
import { Field } from "react-final-form";

export const Input = observer((props) => {
  const { name, label, type, validator, placeholder, ...otherProps } = props;
  return (
    <S.FieldWrapper type={type}>
      <Field
        name={name}
        validate={validator}
        render={({ input, meta }) => {
          const hasError =
            (meta.error || meta.submitError) && meta.error && meta.touched;
          return (
            <div {...(hasError && { className: "ls-error" })}>
              <label>{label}</label>
              <SemanticInput
                {...input}
                {...otherProps}
                type={type}
                placeholder={placeholder || label}
              />
              {hasError && <span>{meta.error || meta.submitError}</span>}
            </div>
          );
        }}
      />
    </S.FieldWrapper>
  );
});
