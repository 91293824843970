import { observer } from "mobx-react-lite";
import { Sidebar, Menu as SemanticMenu } from "semantic-ui-react";
import { Icon } from "components/ui/Icon";
import { useEffect, useState } from "react";
import * as S from "./style";
import { Link, useLocation } from "react-router-dom";
import { UnstyledButton } from "components/style";
import { useStore } from "structure";

export const Menu = observer(() => {
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <S.Wrapper>
      <UnstyledButton onClick={() => setVisible(true)}>
        <Icon name="menu" size="40px" />
      </UnstyledButton>
      <Sidebar
        animation="overlay"
        icon="labeled"
        direction="right"
        onHide={handleClose}
        flexdir="col"
        visible={visible}
      >
        <NavMenu vertical={true} handleClose={handleClose} />
      </Sidebar>
    </S.Wrapper>
  );
});

const NavMenu = ({ vertical, handleClose }) => {
  const { wpPages } = useStore();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("ראשי");

  useEffect(() => {
    const matchedItem = navigaitionItems.find(
      (item) => item.to === location.pathname
    );
    if (matchedItem) {
      setActiveItem(matchedItem.name);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    setTimeout(() => {
      handleClose();
    }, 100);
  };

  const site_content = [
    "תקנון האתר",
    "מדיניות פרטיות",
    "טרנסיות ישראל העמותה",
    "הצהרת נגישות",
    "פעילות ציבורית",
    "הבית הטרנסי",
    "חזון",
    "עלינו",
  ];

  const additionalPages = wpPages
    .map((page) => ({
      name: page.title.rendered,
      to: `/page/${page.slug}`,
    }))
    .filter((item) => !site_content.includes(item.name));

  const navigaitionItems = [
    { name: "ראשי", to: "/" },
    { name: "עלינו", to: "/about" },
    { name: "הבית הטרנסי", to: "/trans-house" },
    { name: "פעילות ציבורית", to: "/public-actions" },
    { name: "אירועים", to: "/events" },
    { name: "מאגר מידע", to: "/info" },
    ...additionalPages,
    { name: "פניה לצוות", to: "/contact" },
    { name: "אני רוצה להתנדב", to: "mailto:ceo.israeltrans@org.il" },
  ];

  return (
    <>
      <UnstyledButton onClick={handleClose} className="ls-close">
        <Icon name="close" />
      </UnstyledButton>
      <SemanticMenu secondary vertical={vertical}>
        {navigaitionItems.map((item, idx) => (
          <SemanticMenu.Item
            key={idx}
            name={item.name}
            as={Link}
            to={item.to}
            active={activeItem === item.name}
            onClick={handleItemClick}
          />
        ))}
      </SemanticMenu>
    </>
  );
};
