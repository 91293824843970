import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 40px 0 48px;
  color: white;
  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    > a {
      color: white;

      &:hover {
        text-shadow: 0px 0px 16px white;
      }
    }
  }

  @media (max-width: 550px) {
    font-size: 0.78em;
    flex-wrap: wrap;
    svg {
      width: auto;
    }
  }
`;
