import styled from "styled-components";
import { Card } from "semantic-ui-react";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  min-height: 53vh;
  padding: 24px 0;
  background-color: var(--blue);

  h2 {
    text-wrap: nowrap;
    display: flex;
    gap: 28px;
    overflow: hidden;
    margin-top: 16px;
    margin-bottom: 16px;
    color: var(--primary-light);

    > img,
    .img {
      width: 220px;
    }
  }

  .ls-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 60px;

    .ls-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      h3 {
        font-size: 32px;
        color: white;
      }
    }
  }

  .ls-empty {
    color: var(--blue-light);
    margin: 80px auto;
    background: var(--blue-soft);
    padding: 1em 2em;
    border-radius: 8px;
    font-weight: 600;
  }

  .ls-carusel-item {
    display: flex !important;
    justify-content: center;
  }
`;

export const EventCard = styled(Card)`
  &.ui.card {
    direction: rtl;
    font-family: "Raleway", "OpenSans", sans-serif !important;
    background: var(--primary-light);
    color: var(--blue);
    border-radius: 16px;
    box-shadow: none;
    width: 200px;

    .header {
      font-family: "Raleway", "OpenSans", sans-serif !important;
      color: var(--blue);
      height: 40px;
      line-height: 1 !important;
    }

    .description {
      height: 124px;
      overflow: hidden;
      margin-bottom: 16px;
      color: var(--blue);
    }

    .meta {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      gap: 0px;
      & > div {
        margin: 0;
        display: flex;
        flex-direction: row;
        gap: 8px;
        span {
          font-size: 1em;
        }
        > * {
          margin: 0;
        }
      }
      color: var(--blue);
    }

    a {
      padding: 0;
      margin-top: 12px;
      justify-content: flex-end;
      > span {
        font-size: 1em;
      }
      > div > svg {
        height: 1em;
      }
    }
  }
`;
