
import { articles } from "../endpoints";
import { Items } from "./Items";
import { makeObservable, computed } from "mobx";

export class Articles extends Items {
    constructor() {
        super();
        this.getItems = articles.getArticles;
        makeObservable(this, {
            articles: computed,
        })
    }

    get articles() {
        return Array.isArray(this.items) ? this.items : [];
    }



}