import { Wrapper } from "./style";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Outlet, useLocation } from "react-router-dom";
import { Nav } from "components/ui/Nav";
import { useEffect } from "react";

export const Layout = () => {
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes("#")) window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Wrapper>
      <Header />
      <Nav />
      <Outlet />
      <Footer />
    </Wrapper>
  );
};
