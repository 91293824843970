import { Hero } from "./Hero";
import { About } from "./About";
import { Wrapper } from "./style";
import { Map } from "./Map";

export const Home = () => {
  return (
    <Wrapper>
      <Hero />
      <About />
      <Map />
    </Wrapper>
  );
};
