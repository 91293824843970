import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "structure";
import { Container } from "semantic-ui-react";
import { Loader } from "components/ui/Loader";
import { Icon } from "components/ui/Icon";
import { SectionWrapper } from "./style";
import Title from "assets/graphics/עלינו/מסמכי עמותהmdpi.svg";
import { Fade } from "react-awesome-reveal";

export const Docs = observer(({ isDisabled }) => {
  const { docsStore } = useStore();
  const { isLoading, docs } = docsStore;

  useEffect(() => {
    docsStore.fetchItems(null, ["file"]);
    // eslint-disable-next-line
  }, []);

  return (
    <SectionWrapper>
      <Container>
        <div className="ls-main ls-docs">
          <Fade
            duration={isDisabled ? 0 : 1000}
            direction="right"
            triggerOnce={true}
          >
            <h2>
              <img src={Title} alt="מסמכי העמותה" className="section-title" />
            </h2>
          </Fade>
          <div className="ls-content">
            <br />
            <br />
            <div>
              <Fade
                duration={isDisabled ? 0 : 1000}
                direction="left"
                cascade
                damping={0.1}
                triggerOnce={true}
              >
                <p>עמותת טרנסיות ישראל מתנהלת בשקיפות מלאה ובאישור.</p>
                <p>מסמכי העמותה זמינים להורדה כקובץ PDF.</p>
              </Fade>
            </div>

            {isLoading ? (
              <Loader inverted />
            ) : (
              <Fade
                duration={isDisabled ? 0 : 1000}
                direction="left"
                cascade
                damping={0.1}
                triggerOnce={true}
              >
                <ul>
                  {docs.map((doc) => {
                    const name = doc.title.rendered;
                    const file = doc.acf.file;
                    return (
                      <li key={doc.id}>
                        <a href={file} target="_blank" rel="noreferrer">
                          <Icon name="download" size="1em" />
                          <span>{name}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Fade>
            )}
          </div>
        </div>
      </Container>
    </SectionWrapper>
  );
});
