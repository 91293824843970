const BASE_URL_API = "https://transisrael.org.il/wp-json/";

const createHeaders = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

const GET = (endpoint, paramsSource = {}) => {
  let paramsUri = "";
  const params = { per_page: 100, ...paramsSource };

  paramsUri =
    "?" +
    Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

  return fetch(`${BASE_URL_API}${endpoint}${paramsUri}`, {
    method: "GET",
    headers: createHeaders(),
  }).then((response) => response.json());
};

// eslint-disable-next-line
const POST = (endpoint, body = {}) => {
  return fetch(`${BASE_URL_API}${endpoint}`, {
    method: "POST",
    headers: createHeaders(),
    body: JSON.stringify(body),
  }).then((response) => response.json());
};
// eslint-disable-next-line
const PUT = (endpoint, id, body) => {
  return fetch(`${BASE_URL_API}${endpoint}/${id}`, {
    method: "PUT",
    headers: createHeaders(),
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "success") {
        return response.data;
      } else {
        console.error(response);
      }
    });
};
// eslint-disable-next-line
const DELETE = (endpoint, id) => {
  return fetch(`${BASE_URL_API}${endpoint}/${id}`, {
    method: "DELETE",
    headers: createHeaders(),
  });
};

const prePath = "wp/v2/";

export const general = {
  getSettings: (params) => {
    return GET(prePath, params);
  },
  getMedia: (id) => {
    return GET(prePath + "media/" + id);
  },
  getWpPages: () => {
    return GET(prePath + "pages/");
  },
  sendEmail: (data) => {
    return POST("contact/v1/send", data);
  },
};

export const pages = {
  getPages: (params) => {
    return GET(prePath + "posts", params);
  },
};

export const posts = {
  getPosts: (params) => {
    return GET(prePath + "posts", params);
  },
};

export const events = {
  getEvents: (params) => {
    return GET(prePath + "events", params);
  },
};

export const partners = {
  getPartners: (params) => {
    return GET(prePath + "partners", params);
  },
};

export const goals = {
  getGoals: (params) => {
    return GET(prePath + "goals", params);
  },
};

export const papers = {
  getPapers: (params) => {
    return GET(prePath + "papers", params);
  },
};

export const docs = {
  getDocs: (params) => {
    return GET(prePath + "docs", params);
  },
};

export const articles = {
  getArticles: (params) => {
    return GET(prePath + "articles", params);
  },
};

export const team = {
  getTeam: (params) => {
    return GET(prePath + "team", params);
  },
};

export const gallery = {
  getImgs: (params) => {
    return GET(prePath + "img", params);
  },
};

export const qna = {
  getQna: (params) => {
    return GET(prePath + "qna", params);
  },
};
