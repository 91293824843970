import styled from "styled-components";

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  position: sticky;
  top: -2px;
  padding-top: 2px;
  background: white;
  box-shadow: 0px 8px 16px #0000001a;
  z-index: 99;

  .ui.grid > .column {
    display: flex;
    align-items: center;

    &:last-child {
      justify-content: flex-end;
      gap: 16px;
    }
  }

  .ls-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    & > div {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 16px;

      .ls-support {
        > button {
          display: flex;
          align-items: center;
          color: var(--primary);
          text-align: center;
          font-weight: 900;
          background: transparent;
          border: none;
          cursor: pointer;
          :hover {
            filter: drop-shadow(0px 0px 8px var(--primary-soft)) saturate(110%);
          }

          svg {
            height: 32px;
            width: 32px;
            transition: 0.4s;
          }
        }

        > ul {
          position: absolute;
          top: 48px;
          background: white;
          list-style-type: none;
          padding-inline-start: unset;
          min-width: 228px;
          color: var(--primary);
          font-weight: 600;
          max-height: 0;
          overflow: hidden;

          li a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 8px 12px 8px 16px;
            cursor: pointer;
            font-size: 16px;
            gap: 4px;
            filter: none;

            svg {
              height: 12px;
              width: 12px;
            }

            :hover {
              background-color: var(--primary-light);
            }
          }
        }
      }

      .ls-support > a {
        font-weight: 800;
        padding: 10px 0;
      }

      a.ls-logo {
        margin: -10px 10px -5px;
        svg {
          width: 100px;
          height: 50px;
        }
      }

      a {
        color: var(--primary);
        font-weight: 500;
        text-align: center;

        &.ls-primary-button {
          background: var(--primary);
          display: flex;
          align-items: center;
          gap: 4px;

          color: white;
          padding: 0.4em 1.2em;
          border-radius: 2em;

          svg {
            height: 20px;
            width: 20px;
          }
        }
        &:hover {
          filter: drop-shadow(0px 0px 8px var(--primary-soft)) saturate(110%);
        }
      }
    }
  }

  @media (max-width: 600px) {
    .ls-support {
      display: none;
    }
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .ls-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 0px;
    & > div {
      display: flex;
      height: 2em;
      .ui.button {
        position: relative;
        top: 2em;
        right: 2em;
      }
    }
  }
  .ls-title {
    text-align: center;
    height: 2em;
    margin-bottom: 2em;
  }
  .ls-navigate {
    margin: 2.5em;
    .ui.button {
      color: var(--primary);
    }
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  gap: 8px;

  a svg {
    height: 24px;
    width: 24px;
    fill: var(--primary);
  }

  @media (max-width: 767px) {
    display: none;
  }
`;
