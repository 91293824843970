import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "structure";
import { Wrapper, EventCard } from "./style";
import { Container, Card } from "semantic-ui-react";
import { Loader } from "components/ui/Loader";
import { LinkWithIcon } from "../../Home/style";
import { Icon } from "components/ui/Icon";
import { currentMonth, currentYear } from "./eventHelper";
import { Carousel } from "components/ui/Carousel";
import Title from "assets/graphics/דף הבית/לוח אירועי הביתmdpi.svg";
import { Fade } from "react-awesome-reveal";

const today = new Date();
const currMonthIndex = today.getMonth();

export const Events = observer(() => {
  const { eventsStore } = useStore();
  const { isLoading, events: allEvenets } = eventsStore;
  const currMonth = currentMonth();
  const currYear = currentYear();

  const events = allEvenets.filter(
    (event) => Number(event?.acf?.date?.slice(4, 6)) - 1 === currMonthIndex
  );

  useEffect(() => {
    eventsStore.fetchItems();
    // eslint-disable-next-line
  }, []);

  const dateTitle = currMonth + " " + currYear;
  return (
    <Wrapper id="calendar">
      <Fade direction="down" fraction={1} triggerOnce={true}>
        <h2>
          {new Array(20).fill(null).map((_, idx) => (
            <img
              key={idx}
              src={Title}
              alt="לוח אירועי הבית"
              className="major-title"
            />
          ))}
        </h2>
      </Fade>
      <Container>
        {isLoading ? (
          <Loader inverted />
        ) : (
          <div className="ls-main">
            <div className="ls-title">
              <Fade
                direction="up"
                damping={0.1}
                fraction={0.8}
                triggerOnce={true}
              >
                <h3>{dateTitle}</h3>
                <LinkWithIcon to="/events">
                  <span>לכל אירועי הבית הטרנסי</span>
                  <Icon name="left" size="1em" />
                </LinkWithIcon>
              </Fade>
            </div>
            {events.length > 0 ? (
              <Fade delay={500} fraction={0.6}>
                <Carousel>
                  {events.map((event) => {
                    const title = event?.title?.rendered || "";
                    const date = getDate(event?.acf?.date);
                    const time = event?.acf?.time || "";
                    const desc = event?.acf?.desc || "";
                    const descShow =
                      desc.length > 100 ? desc.slice(0, 100) + "..." : desc;
                    return (
                      <div key={event.id} className="ls-carusel-item">
                        <EventCard>
                          <Card.Content>
                            <Card.Header>{title}</Card.Header>

                            <Card.Description
                              dangerouslySetInnerHTML={{ __html: descShow }}
                            />
                            <Card.Meta>
                              <div className="ls-date">
                                <Icon name="date" height="16px" width="16px" />
                                <span>{date}</span>
                              </div>
                              <div className="ls-time">
                                <Icon name="time" height="16px" width="16px" />
                                <span>{time}</span>
                              </div>
                            </Card.Meta>
                            <LinkWithIcon to={`/event/${event?.id}`}>
                              <span>פרטים נוספים</span>
                              <Icon name="left" size="0.7em" />
                            </LinkWithIcon>
                          </Card.Content>
                        </EventCard>
                      </div>
                    );
                  })}
                </Carousel>
              </Fade>
            ) : (
              <Fade delay={500}>
                <p className="ls-empty">אין אירועים נוספים החודש</p>
              </Fade>
            )}
          </div>
        )}
      </Container>
    </Wrapper>
  );
});

const getDate = (date) => {
  if (!date) return "-";
  return date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4);
};
