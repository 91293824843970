import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw !important;
  min-height: 100vh;
  overflow: hidden;
  .logo {
    border-bottom: 1px solid #a3afec;
  }
  h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    margin: 8px;
    color: #364488;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: var(--page-containter);
  margin: var(--page-margin);
`;

export const SectionHeader = styled.section`
  width: 100vw;
  height: 16em;
  background: rgb(36, 88, 231);
  background: linear-gradient(
    61deg,
    rgba(36, 88, 231, 1) 0%,
    rgba(233, 31, 123, 1) 100%
  );
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  background: white;

  h2 {
    font-size: 2em;
    color: var(--blue);
    margin-bottom: 0.2em;
  }

  .ls-qna-section {
    margin: 4em auto;
    max-width: 1080px;

    .ls-qna-items {
      color: var(--primary);
      display: flex;
      flex-direction: column;
      gap: 1.4em;

      .ls-qna-item {
        button {
          display: flex;
          align-items: center;
          flex-direction: row;
          border: none;
          background: none;
          font-size: 1.2em;
          color: var(--primary);
          font-weight: 900;
          padding: 12px 0;
          cursor: pointer;
          text-align: right;

          svg {
            height: 30px;
            width: 30px;
            margin-left: 8px;
          }
        }

        pre {
          color: var(--black);
          margin-right: 38px;
          animation: fromLeft 0.4s;
          white-space: break-spaces;
          overflow-wrap: anywhere;
          text-wrap: wrap;
          &.ls-close {
            display: none;
          }
        }
      }
    }
  }
`;

export const ButtonWithIcon = styled(Button)`
  &.ui.button {
    background: transparent;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    svg {
      transition: 0.3s;
    }

    &:hover svg {
      transform: translateX(-4px);
    }
  }
`;

export const LinkWithIcon = styled(Link)`
  background: transparent;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    transition: 0.3s;
  }

  &:hover {
    color: white;
    filter: drop-shadow(0px 0px 12px white);
    svg {
      transform: translateX(-4px);
    }
  }
`;
