import { useEffect, useState } from "react";
import { useStore } from "structure";
import { Loader } from "../Loader";

export const Media = ({ id, imgProps }) => {
  const { getMedia } = useStore();
  const [mediaSrc, setMediaSrc] = useState(null);
  useEffect(() => {
    getMedia(id).then((media) => {
      setMediaSrc(media);
    });
    // eslint-disable-next-line
  }, []);

  if (!mediaSrc) {
    return (
      <div
        className="img"
        style={{
          background: "#ffffff08",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          animation: "fadeIn 1s infinite",
        }}
      >
        <Loader inline inverted />
      </div>
    );
  }
  return (
    <img
      className="img"
      src={mediaSrc.source_url}
      {...imgProps}
      alt={mediaSrc.title?.rendered || "image"}
      style={{
        animation: "fadeIn 1s",
      }}
    />
  );
};
