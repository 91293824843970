import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo {
    border-bottom: 1px solid #a3afec;
  }
  h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    margin: 8px;
    color: #364488;
  }
`;

export const HomeSection = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1vw;
  overflow: hidden;
  background: ${({ color }) => color || "var(--primary)"};

  .ls-text {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ color }) => color || "var(--primary)"};

    > div {
      display: flex;
      padding: 4vw;
      gap: 2vw;
      flex-direction: column;
      align-items: stretch;

      h1 {
        font-size: 4vw;
        line-height: 1.12;
        color: white;
        display: flex;
        flex-direction: column;

        .text {
          font-weight: 400;
          font-size: 2vw;
        }
      }

      img {
        width: 25vw;
        height: auto;

        :first-child {
          margin-bottom: -1.5vw;
        }
      }
      .ls-content-logo svg {
        margin-top: 1.5vw;
        width: 25vw;
        height: auto;
      }

      a {
        background: ${({ btnBgColor }) => btnBgColor || "#ff7bac"};
        color: ${({ color }) => color || "white"};
        border-radius: 80px;
        font-size: 20px;
        padding: 8px 16px;
        justify-content: center;
        font-weight: 700;
        box-shadow: 8px 8px 24px #00000059;
      }

      .ls-contact {
        a {
          padding: 4px;
          color: white;
          box-shadow: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row-reverse;
          gap: 12px;
          font-size: clamp(14px, 1.6vw, 18px);
          font-weight: 400;
        }

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  .ls-graphics {
    width: 60%;
    min-height: min(70vh, 20vw);

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center right;
    }

    &:first-child img {
      object-position: center left;
    }
  }

  .ls-graphics-main {
    width: 60%;
    height: 100%;
    .ls-bg {
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      min-height: min(70vh, 20vw);
      height: 100%;
      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center right;
      }

      .ls-over {
        position: absolute;
        left: 0;
        top: 10%;
        height: 80%;
        width: 100%;
        margin: 0;
        display: flex;
        overflow: hidden;
        justify-content: flex-start;
        list-style-type: none;

        li {
          display: flex;
          flex-direction: row;
          width: 25%;
          height: 100%;
          img {
            max-width: 80%;
            height: 100%;
            object-fit: cover;
          }
          :hover > .ls-name {
            opacity: 1;
            transform: rotate(-90deg) translate(0px, -10.5vw);
          }
          .ls-name {
            opacity: 0;
            transition: 0.4s;
            position: absolute;
            transform-origin: 100% 100%;
            transform: rotate(-90deg) translate(30px, -10.5vw);
            display: flex;
            flex-direction: row-reverse;
            align-content: space-around;
            justify-content: space-between;
            width: 200%;
            font-size: 0.7em;
            color: white;
            font-weight: 700;
            > span:first-child {
              padding: 2px 12px;
            }
            > span:last-child {
              width: fit-content;
              display: block;
              flex: 1;
              background: linear-gradient(45deg, var(--primary), transparent);
            }
          }
        }
      }
    }
  }

  .graphic-desc {
    position: absolute;
    left: 0;
    font-size: 12px;
    color: white;
    transform: translateX(calc(24px - 50%)) rotate(-90deg) translateX(-100%);
  }

  .contact-details {
    width: 33vw;

    h1 img {
      width: 20vw;
    }
  }

  @media only screen and (max-width: 850px) {
    flex-direction: column;

    &.ls-about-in-hero {
      margin-top: 4px;
      margin-bottom: 12px;
      flex-direction: column-reverse;
    }

    .ls-text {
      width: 100%;
      padding: 3em 1em 4em;
      > div {
        h1 {
          font-size: 10vw;
          text-align: center;

          .text {
            font-weight: 400;
            font-size: 4vw;
          }
        }

        img {
          width: 60vw;
          height: auto;
        }
        .ls-content-logo svg {
          margin-top: 1.5vw;
          width: 60vw;
          height: auto;
        }
      }
    }

    .graphic-desc {
      position: relative;
      transform: none;
      bottom: 24px;
      right: 20px;
    }

    .ls-graphics {
      width: 100%;
      margin: 0;
    }

    .ls-graphics-main {
      width: 100%;
      margin: 0;

      .ls-bg .ls-over {
        height: 120%;
        top: -10%;
        padding: 0;
        li {
          img {
            max-width: 100%;
          }
          .ls-name {
            width: 250%;
            span:first-child {
              background: var(--primary);
            }
          }
        }
      }
    }

    .contact-details {
      width: 90vw;

      h1 img {
        width: 40vw;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: var(--page-containter);
  margin: var(--page-margin);
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 53vh;
`;

export const ButtonWithIcon = styled(Button)`
  &.ui.button {
    background: transparent;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    svg {
      transition: 0.3s;
    }

    &:hover svg {
      transform: translateX(-4px);
    }
  }
`;

export const LinkWithIcon = styled(Link)`
  background: transparent;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    transition: 0.3s;
  }

  &:hover {
    color: white;
    filter: drop-shadow(0px 0px 12px white);
    svg {
      transform: translateX(-4px);
    }
  }
`;
