import styled from 'styled-components';

export const Wrapper = styled.div`
    width:  100%;

    .ls-swiper {
        width: 100vw;
        position: relative;
        margin: 0 -5vw;
    }

    .swiper-slide {
        width: min(300px, 65%);
        margin-left: min(5%, 24px);
      }

      .swiper-horizontal {
        width: 100vw;
        padding: 0 5vw 0 10vw;
      }

      .swiper-3d .swiper-slide-shadow {
        background: transparent !important;
      }

      .ls-arrows {
        display: flex;
        justify-content: space-between;
        padding: 16px 5vw;

        button {
            border: none;
            color: var(--gray-dark);
            border-radius: 100%;
            padding: 8px;
            background: var(--blue-soft);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0px 0px 16px #00000011;

            &.swiper-button-disabled {
                opacity: 0.5;
            }

            &:hover {
                transform: scale(1.1);
            }

            &:active {
                background: var(--white);
            }

            svg {
                transform: translateX(2px);
            }
            :last-child svg {
                transform: translateX(-2px);
            }
        }
      }
      

    @media (max-width: 1000px) {
        max-width: calc(100% + 4em);
        width: calc(100% + 4em);
        position: relative;
        right: 0;
    } 
`;