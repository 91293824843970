import { Fade } from "react-awesome-reveal";
import { SectionWrapper } from "./style";
import { Container } from "semantic-ui-react";
import Title from "assets/graphics/פעילות ציבורית/פעילות ציבוריתmdpi.svg";
import Subtitle from "assets/graphics/פעילות ציבורית/שינוי חקיקה ולובינגmdpi.svg";
import { useStore } from "structure";
import { observer } from "mobx-react-lite";

export const AboutSection = observer(() => {
  const { wpPages } = useStore();
  const page = wpPages.find((page) => page.slug === "about-content");
  return (
    <SectionWrapper>
      <Container>
        <div className="ls-main ls-about">
          <div>
            <Fade direction="right" triggerOnce={true}>
              <h2>
                <img
                  src={Title}
                  alt="פעילות ציבורית"
                  className="section-title"
                />
              </h2>
              <h3>
                <img
                  src={Subtitle}
                  alt="שינוי חקיקה ולובינג"
                  className="section-title"
                />
              </h3>
            </Fade>
          </div>

          <div className="ls-content">
            <Fade direction="left" cascade damping={0.1} triggerOnce={true}>
              {page && (
                <div
                  dangerouslySetInnerHTML={{ __html: page.content.rendered }}
                />
              )}
            </Fade>
          </div>
        </div>
      </Container>
    </SectionWrapper>
  );
});
