import { makeObservable, observable, action } from "mobx";
import { general } from "./endpoints";
import { Events } from "./stores/Events";
import { Pages } from "./stores/Pages";
import i18n from "i18next";
import { Partners } from "./stores/Partners";
import { Goals } from "./stores/Goals";
import { Docs } from "./stores/Docs";
import { Articles } from "./stores/Articles";
import { Papers } from "./stores/Papers";
import { Team } from "./stores/Team";
import { QnA } from "./stores/QnA";
import { Gallery } from "./stores/Gallery";

class RootStore {
  isFirstLoading = true;
  settings = {};
  wpPages = [];
  lang = 1;

  constructor() {
    this.pagesStore = new Pages(this);
    this.teamStore = new Team(this);
    this.eventsStore = new Events(this);
    this.partnersStore = new Partners(this);
    this.goalsStore = new Goals(this);
    this.docsStore = new Docs(this);
    this.articlesStore = new Articles(this);
    this.papersStore = new Papers(this);
    this.getMedia = general.getMedia;
    this.infoStore = new QnA(this);
    this.galleryStore = new Gallery(this);

    //fetch first loading
    this.fitstLoading();

    makeObservable(this, {
      isFirstLoading: observable,
      wpPages: observable.ref,
      lang: observable,
      fitstLoading: action,
      changeLanguage: action,
    });
  }

  changeLanguage(lang) {
    if (lang) {
      this.lang = lang;
      i18n.changeLanguage(lang === 2 ? "he" : "en");
    }
  }

  async fitstLoading() {
    // fetch("https://ipapi.co/json/")
    //   .then((res) => res.json())
    //   .then((response) => {
    //     if (response.country === "IL") this.changeLanguage(2);
    //   });
    this.wpPages = await general.getWpPages();
    this.isFirstLoading = false;
  }
}

const rootStore = new RootStore();
export const useStore = () => {
  return rootStore;
};
