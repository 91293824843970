import { observer } from "mobx-react-lite";
import * as S from "./style";
import { Dropdown as SemanticDropdown } from "semantic-ui-react";
import { Field } from "react-final-form";

export const Dropdown = observer((props) => {
  const {
    keyname,
    name,
    label,
    options = [],
    defaultValue,
    placeholder,
    customOnChange,
    value,
  } = props;
  return (
    <S.FieldWrapper>
      <Field
        name={name}
        initialValue={defaultValue}
        render={({ input, meta }) => (
          <div>
            <label>{label}</label>
            <SemanticDropdown
              key={keyname}
              {...input}
              search
              selection
              options={options}
              onChange={(e, data) => {
                input.onChange(data.value);
                customOnChange && customOnChange(data.value);
              }}
              placeholder={placeholder || label}
              {...(value ? { value: value } : {})}
            />
            {meta.touched && meta.error && <span>{meta.error}</span>}
          </div>
        )}
      />
    </S.FieldWrapper>
  );
});
