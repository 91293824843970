import styled from "styled-components";
import bg from "assets/icons/bg.svg";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw !important;
  overflow: hidden;
  .logo {
    border-bottom: 1px solid #a3afec;
  }
  h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    margin: 8px;
    color: #364488;
  }

  > section:nth-child(2) {
    background-image: url(${bg}) !important;
    background-repeat: repeat !important;
    background-size: 48px !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: var(--page-containter);
  margin: var(--page-margin);
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 53vh;
  background-color: var(--blue);

  .ls-main {
    display: grid;
    grid-template-columns: 20% min(80%, 600px);
    gap: 24px;
    margin: 12vh 0;
    color: white;
  }
  .section-title {
    max-width: 92%;
  }

  &.ls-gallery {
    .section-title {
      margin: 48px 0 24px;
      height: 2em;
      width: auto;
      max-width: calc(100vw - 4em);
    }
    .ls-carusel-item > div {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: min(400px, 80vh);
        max-width: 80vw;
        aspect-ratio: 16 / 9;
        object-fit: cover;
      }
    }

    .ls-images {
      margin: 28px auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (max-width: 900px) {
    .ls-main {
      grid-template-columns: 1fr;

      .section-title {
        max-width: min(90%, 400px);
      }
    }
  }
`;
