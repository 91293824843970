import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;

    svg {
        height: ${({height, size}) => height || size || "44px"};
        width: ${({width, size}) => width || size || "44px"};
    }
`;