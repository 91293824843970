export const months = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "דצמבר",
];
export const weekDays = [
  "ראשון",
  "שני",
  "שלישי",
  "רביעי",
  "חמישי",
  "שישי",
  "שבת",
];

export const currentMonth = () => {
  const today = new Date();
  return today.getMonth();
};

export const currentYear = () => {
  const today = new Date();
  return today.getFullYear();
};

export const closeYears = (startDate) => {
  const start = startDate.getFullYear();
  return [start, start + 1, start + 2, start + 3];
};

export const closeDays = (startDate) => {
  const daysTotal = daysInMonth(startDate.getMonth(), startDate.getFullYear());
  let res = new Array(daysTotal).fill(null);
  for (let i = 0; i < daysTotal; i++) {
    res[i] = new Date(moveDays(startDate, i));
  }
  return res;
};

function daysInMonth(month, year) {
  return new Date(year, month + 1, 0).getDate();
}

export const getWeekDay = (date) => {
  const day = date.getDay();
  return weekDays[day];
};

export const moveDays = (date, days) => {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
};

export const toSystemDate = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  return `${date.getFullYear()}${month < 10 ? `0${month}` : month}${
    day < 10 ? `0${day}` : day
  }`;
};

// export const toDateString = (date) => {
//     if (typeof date !== "Date") return "-";

//     const parts = {
//       day: date.getDate(),
//       month: months[date.getMonth()],
//       year: date.getFullYear(),
//       weekDay: getWeekDay(date),
//     };
//     return `יום ${parts.weekDay} ${parts.day} ב${parts.month} ${parts.year}`;
//   }
