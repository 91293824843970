
import { pages } from "../endpoints";
import { Items } from "./Items";
import { makeObservable, computed } from "mobx";

export class Pages extends Items {
    constructor() {
        super();
        this.getItems = pages.getPages;
        makeObservable(this, {
            pages: computed,
        })
    }

    get pages() {
        return Array.isArray(this.items) ? this.items : [];
    }



}