import { observer } from "mobx-react-lite";
import { Container } from "semantic-ui-react";
import * as S from "./style";
import { Icon } from "components/ui/Icon";
import { HashLink } from "react-router-hash-link";

const NavItem = ({ to, children }) => (
  <HashLink to={to} scroll={(el) => scrollWithOffset(el, 80, to.includes("#"))}>
    {children}
  </HashLink>
);

const scrollWithOffset = (el, offset, isPart) => {
  const elementPosition = isPart ? el.offsetTop - offset : 0;
  window.scrollTo({
    top: elementPosition,
    behavior: "smooth",
  });
};

export const Footer = observer(() => {
  return (
    <S.Wrapper>
      <Container>
        <div className="ls-main">
          <S.SocialWrapper>
            {/* <a href="/" target="_blank" rel="noreferrer">
              <Icon name="tiktok" />
            </a> */}
            <a
              href="https://www.instagram.com/trans.israel"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="instegram" />
            </a>
            <a
              href="https://www.facebook.com/transisraelngo"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="facebook" />
            </a>
            <a
              href="mailto:ceo.israeltrans@org.il"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="email" />
            </a>
            <a href="tel:0523005280" target="_blank" rel="noreferrer">
              <Icon name="phone" />
            </a>
          </S.SocialWrapper>
          <div className="ls-row-1">
            <p>טרנסיות ישראל</p>
            <span>(ע"ר) 580709392</span>
          </div>

          <div className="ls-sitemap">
            <NavItem to="/terms">תנאי שימוש</NavItem>|
            <NavItem to="/accessibility">הצהרת נגישות האתר</NavItem>
          </div>

          <div className="ls-rights">
            <p>2024 © כל הזכויות שמורות לעמותת 'טרנסיות ישראל'</p>
          </div>
        </div>
      </Container>
    </S.Wrapper>
  );
});
