import { Team } from "./Team";
import * as S from "./style";
import { NavLink } from "react-router-dom";

export const Contact = () => {
  return (
    <S.Wrapper>
      <Team />
      {/* <div className="moreBtns">
        <NavLink to="/about">עוד עלינו</NavLink>
      </div> */}
    </S.Wrapper>
  );
};
