import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { LoadingWrapper } from "./style";

export const Loading = observer(({ isLoading }) => {
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);

  if (!isLoading) {
    return null;
  }

  return (
    <LoadingWrapper>
      <div className="loader"></div>
    </LoadingWrapper>
  );
});
