import { Fade } from "react-awesome-reveal";
import { AboutSection } from "./AboutSection";
import { Articles } from "./Articles";
import { Papers } from "./Papers";
import { SectionHeader, Wrapper } from "./style";

export const PublicActions = () => {
  return (
    <Wrapper>
      <Fade triggerOnce={true}>
        <SectionHeader />
      </Fade>
      <AboutSection />
      <Papers />
      <Articles />
    </Wrapper>
  );
};
