import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  background-color: var(--blue);
  background-image: url(https://transisrael.org.il/wp-content/uploads/2024/03/background-patternmdpi.svg);
  background-position: 25% -50%;
  background-size: 120%;
  background-repeat: no-repeat;

  .ls-conteiner {
    display: flex;
    gap: 24px;

    .ls-img,
    .img {
      height: 200px;
      width: 200px;
      img,
      .img {
        height: 200px;
        width: 200px;
        border-radius: 12px;
        box-shadow: 0px 4px 24px #0000001f;
      }
    }

    .ls-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      color: white;
      .ls-title {
        font-size: 1.4em;
      }
      .ls-date {
        opacity: 0.8;
      }
      .ls-desc {
        margin-top: 16px;
        font-size: 1em;
      }
      .ls-meta {
        font-size: 1.17em;
        font-weight: 500;
        & > div {
          display: flex;
          gap: 8px;
        }
      }

      .ls-sell-tickets {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin: 32px 0 64px;

        & > p {
          font-size: 1em;
          margin-right: 2px;
        }

        > a {
          font-size: 1.5em;
          padding: 0.5em 2em;
        }
      }

      .ls-form-reg {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin: 32px 0 64px;

        & > p {
          font-size: 1em;
          margin-right: 2px;
        }

        & > form {
          background: white;
          width: 100%;
          min-width: min(600px, 90vw);
          border-radius: 12px;
          padding: 12px 24px;

          & > div {
            width: 100%;

            label {
              color: var(--blue);
              font-size: 1em;
            }

            .ui.input {
              width: 100%;
              input {
                border-radius: 8px;
                border: none;
                background: var(--gray-soft);
                color: var(--blue);
                font-weight: 500;
              }
            }

            .ls-error {
              input {
                border: 1px solid var(--primary-dark);
              }
              span {
                color: var(--primary-dark);
                float: left;
                font-size: 1em;
                margin-right: 1em;
              }
            }
          }
        }
      }

      .ls-gallery {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin: 20px 0;

        & > div {
          width: 50%;
          height: 240px;

          img,
          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 12px;
            box-shadow: 0px 8px 24px #0000001f;
          }
        }
      }
    }

    .ls-success-message {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      color: var(--primary);
      font-size: 1.5em;
      font-weight: 700;
      padding: 32px 0;
      border-radius: 12px;
      gap: 16px;
    }
  }

  @media (max-width: 888px) {
    .ls-conteiner {
      flex-direction: column;
    }
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;

  button {
    margin-right: auto;
    border-radius: 30px;
    padding: 0.5em 3em;
    font-size: 1.1em;
    background: var(--blue);

    &:hover {
      background: #244cb2;
    }
  }
`;
