
import { partners } from "../endpoints";
import { Items } from "./Items";
import { makeObservable, computed } from "mobx";

export class Partners extends Items {
    constructor() {
        super();
        this.getItems = partners.getPartners;
        makeObservable(this, {
            partners: computed,
        })
    }

    get partners() {
        return Array.isArray(this.items) ? this.items : [];
    }
}