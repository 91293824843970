import { SectionWrapper } from "./style";
import { Container } from "semantic-ui-react";
import Title from "assets/graphics/עלינו/עלינוmdpi.svg";
import { Fade } from "react-awesome-reveal";
import { useStore } from "structure";
import { observer } from "mobx-react-lite";

export const AboutSection = observer(({ isDisabled }) => {
  const { wpPages } = useStore();
  const page = wpPages.find((page) => page.slug === "about-content");

  return (
    <SectionWrapper id="us">
      <Container>
        <div className="ls-main ls-about">
          <Fade
            duration={isDisabled ? 0 : 1000}
            direction="right"
            triggerOnce={true}
          >
            <h2>
              <img src={Title} alt="עלינו" className="section-title" />
            </h2>
          </Fade>
          <div className="ls-content">
            <Fade
              duration={isDisabled ? 0 : 1000}
              direction="left"
              cascade
              damping={0.1}
              triggerOnce={true}
            >
              {page && (
                <div
                  dangerouslySetInnerHTML={{ __html: page.content.rendered }}
                />
              )}
            </Fade>
          </div>
        </div>
      </Container>
    </SectionWrapper>
  );
});
