import { Loader } from "components/ui/Loader";
import { Wrapper } from "./style";
import { useStore } from "structure";
import { observer } from "mobx-react-lite";

export const Accessibility = observer(() => {
  const { wpPages } = useStore();
  const page = wpPages.find((page) => page.slug === "accessibility");

  if (!page) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <h1>{page.title.rendered}</h1>
      <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
    </Wrapper>
  );
});
