export const composeValidators =
(...validators) =>
(value) =>
  validators.reduce(
    (error, validator) => error || validator(value),
    undefined
  );

export const required = (value) => (value ? undefined : "שדה חובה");
export const email = (value) => {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) ? undefined : "מייל לא תקין";
};
export const mustBeNumber = (value) => (isNaN(value) ? "יש להזין מספר" : undefined);
export const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `מספר צריך להיות גדול מ ${min}`;
