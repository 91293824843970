
import { docs } from "../endpoints";
import { Items } from "./Items";
import { makeObservable, computed } from "mobx";

export class Docs extends Items {
    constructor() {
        super();
        this.getItems = docs.getDocs;
        makeObservable(this, {
            docs: computed,
        })
    }

    get docs() {
        return Array.isArray(this.items) ? this.items : [];
    }



}