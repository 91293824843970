
import styled from 'styled-components';

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 90vh;
.logo {
    border-bottom: 1px solid #A3AFEC;
}
h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    margin: 8px;
    color: #364488;
}
`;

export const LayoutWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-top: 88px;
padding-bottom: 88px;
.logo {
    width: 100px;
    position: absolute;
    top: 8px;
    left: 16px;
}
&>div {
    justify-content: flex-start;
    min-height: calc(80vh - 88px);
    width: 400px;
    max-width: calc(100% - 40px);
}
`;

export const FullPage = styled.div`
    min-height: 90vh;
    display: flex;
    align-items: center;
`;

export const UnstyledButton = styled.button`
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: 0.2s;

    &:hover:not([disabled]) {
        filter: drop-shadow(0px 0px 10px white);

        &:first-child, &:last-child {
        filter: drop-shadow(0px 0px 6px white);
        }
    }

    &[disabled] {
        opacity: 0.5;
    }
`;